import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import store from './store/index';
import './firebaseConfig'; // Firebase yapılandırma dosyasını içe aktarın

axios.defaults.baseURL = "https://golder-31299-default-rtdb.firebaseio.com/";

createApp(App)
    .use(store)
    .use(router)
    .mount('#app');
<template>
    <div class="header-container">
      <div class="header-logo">
        <router-link to="/" class="logo-text">Hızlı,Güvenli Al ve Sat</router-link>
      </div>
      <div class="menuControl">
        <i @click="displayBar" class="menuBtn fas fa-bars"></i>
      </div>
      <nav class="header-nav">
        <router-link class="nav-item" :class="navClass" to="/">İşlem Yap</router-link>
        <router-link class="nav-item" :class="navClass" to="/profil">Profil</router-link>
        <router-link class="nav-item" :class="navClass" to="/wallet">İşlem Geçmişim</router-link>
        <router-link class="nav-item" :class="navClass" to="/support">Destek</router-link>
        <router-link v-if="isAdmin" class="nav-item" :class="navClass" to="/admin">Admin Panel</router-link>
        <router-link v-if="isTrader" class="nav-item" :class="navClass" to="/trader">Satıcı Paneli</router-link>
      </nav>
      <nav class="headerMini none" id="display">
        <router-link @click="addNone" class="nav-item-mini" :class="navClass" to="/">İşlem Yap</router-link>
        <router-link @click="addNone" class="nav-item-mini" :class="navClass" to="/profil">Profil</router-link>
        <router-link @click="addNone" class="nav-item-mini" :class="navClass" to="/wallet">İşlem Geçmişim</router-link>
        <router-link @click="addNone" class="nav-item-mini" :class="navClass" to="/support">Destek</router-link>
        <router-link @click="addNone" v-if="isAdmin" class="nav-item-mini" :class="navClass" to="/admin">Admin Panel</router-link>
      </nav>
      <div class="header-actions">
        <a class="logout-btn" @click.prevent="logout" :class="logoutClass">
          <i class="fas fa-power-off colorLogout"></i>
        </a>
        <div class="auth-links" :class="loginClass">
          <router-link class="auth-link" to="/signup">Kayıt Ol</router-link>
          <router-link class="auth-link" to="/signin">Giriş Yap</router-link>
        </div>
      </div>
    </div>
  </template>  
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'HeaderPage',
    data() {
      return {
        isAdmin: false,
        isTrader: false,
      };
    },
    methods: {
      logout() {
        this.$store.dispatch("logout");
        localStorage.removeItem('localId');
      },
      async checkAdmin() {
        const localId = localStorage.getItem('localId');
        if (localId) {
          try {
            const idToken = await this.$store.dispatch('getToken');
            const response = await axios.get(`https://golder-31299-default-rtdb.firebaseio.com/users/${localId}.json?auth=${idToken}`);
            const user = response.data;
            this.isAdmin = user.isAdmin || false;
            this.isTrader = user.isTrader || false;
          } catch (error) {
            console.error('Error fetching user data:', error);
          }
        }
      },
      displayBar() {
            const displayStyle = window.getComputedStyle(document.querySelector('#display')).display;
            
            if (displayStyle === 'none') {
                document.querySelector('.headerMini').classList.remove('none');
            } else {
                document.querySelector('.headerMini').classList.add('none');
            }
        },
      addNone(){
        const displayStyle = window.getComputedStyle(document.querySelector('#display')).display;
            
            if (displayStyle === 'none') {
                document.querySelector('.headerMini').classList.remove('none');
            } else {
                document.querySelector('.headerMini').classList.add('none');
            }
      }
    },
    computed: {
      logoutClass() {
        return {
          "d-none": !this.$store.getters.isAuthenticated
        };
      },
      loginClass() {
        return {
          "d-none": this.$store.getters.isAuthenticated
        };
      }
    },
    async created() {
      if (this.$store.getters.isAuthenticated) {
        this.checkAdmin();
      }
    }
  };
  </script>
  
  <style scoped>
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background: linear-gradient(to right, #4169E1, #FFA500);
    color: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    position: sticky;
    top: 0;
    z-index: 1000;
    font-family: 'Roboto', sans-serif;
  }
  
  .header-logo {
    flex: 1;
  }
  
  .logo-text {
    font-size: 24px;
    font-weight: bold;
    color: #ffffff;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .logo-text:hover {
    color: #f8f9fa;
  }
  
  .header-nav {
    display: flex;
    align-items: center;
    flex: 2;
  }
  
  .nav-item {
    margin: 0 15px;
    font-size: 16px;
    font-weight: bold;
    color: #ffffffb3;
    text-decoration: none;
    padding: 10px 15px;
    border-radius: 6px;
    transition: font-size 0.3s, color 0.3s;
  }
  
  .nav-item:hover,
  .nav-item.active-nav {
    color: #ffffff;
  }
  
  .header-actions {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  
  .logout-btn {
    margin-left: 20px;
    font-size: 20px;
    background-color: #ffffff;
    cursor: pointer;
    transition: color 0.3s;
    padding: 10px;
    border-radius: 20px;
    transition : ease 0.3s background;
  }
  
  .logout-btn:hover {
    background-color: #f20a15;
  }
  
  .auth-links {
    display: flex;
    align-items: center;
  }
  
  .auth-link {
    margin-left: 15px;
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    text-decoration: none;
    padding: 8px 12px;
    border-radius: 4px;
    background-color: #4169E1;
    transition: background-color 0.3s, color 0.3s;
    border: 1px solid transparent;
  }
  
  .auth-link:hover {
    background-color: #FFA500;
    border: 1px solid #4169E1;
    color: #ffffff;
  }

  .colorLogout {
    color : #e8999d;
    transition : ease 0.3s color
  }

  .colorLogout:hover {
    color: white;
  }

  .headerMini  {
    display: none
  }

  .menuControl {
    display: none;
  }
  
  .d-none {
    display: none;
  }

  @media (max-width: 768px) {
    .header-nav {
      display: none;
    }
    .header-logo {
      display: none;
    }
    .headerMini {
      position: absolute;
      top : 70px;
      left: 0;
      background: linear-gradient(to bottom, #4169E1, #FFA500);
      padding: 10px;
      display: flex;
      flex-direction: column;
      margin: 3px;
      border-radius: 10px;
      transition: ease 1s all;
    }
    .nav-item-mini {
      padding: 10px;
      color: #ffffff;
      text-decoration: none;
      border-bottom: 2px solid rgb(255, 255, 255);
      font-weight: bold;
      background-color: #0057b300;
      transition: ease .8s background;
    }
    .nav-item-mini:hover {
      background: linear-gradient(to bottom, #FFA500, #ffa60041);
    }
    .menuBtn {
      font-size: 30px;
    }
    .menuControl {
      display: block;
    }
    .menuControl:hover {
      cursor: pointer;
    }
    .none {
    display: none;
    }
  }
  </style>
  
<template>
  <div class="container">
    <h3>Şikayet Detayları</h3>
    <p><strong>Kullanıcı ID:</strong> {{ complaint.userId }}</p>
    <p><strong>Konu:</strong> {{ complaint.text }}</p>
    <p><strong>Durum:</strong> {{ complaint.status === 'closed' ? 'Kapatıldı' : 'Açık'}}</p>

    <div @click="fetchComplaintDetails" class="buttonRefresh">
        <i class="fas fa-sync-alt"></i>
    </div>
    <div class="chat-container" ref="chatContainer">
      <div v-for="message in complaint.responses" :key="message.timestamp" :class="['chat-message', { admin: message.sender === 'admin' }]">
        <strong>{{ message.sender }}:</strong> {{ message.text }}
      </div>
    </div>

    <div v-if="complaint.status === 'open'">
      <textarea v-model="responseText" placeholder="Mesajınızı buraya yazın..."></textarea>
      <button @click="sendResponse">Gönder</button>
    </div>
    <div v-else>
      <p>Bu şikayet kapatıldı. Yeni mesaj gönderemezsiniz.</p>
    </div>
    <div v-if="isAdmin && complaint.status === 'open'">
      <button @click="closeComplaint">Sohbeti Kapat</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      complaint: {
        userId: '',
        text: '',
        status: '',
        responses: []
      },
      responseText: '',
      isAdmin: false,
    };
  },
  methods: {
    async fetchComplaintDetails() {
      try {
        const idToken = await this.$store.dispatch('getToken');
        const complaintId = this.$route.params.complaintId;
        const localId = localStorage.getItem('localId');
        
        // Kullanıcı admin mi kontrol edin
        const userResponse = await axios.get(`https://golder-31299-default-rtdb.firebaseio.com/users/${localId}.json?auth=${idToken}`);
        this.isAdmin = userResponse.data.isAdmin;

        // Şikayet detaylarını uygun koleksiyondan çekin
        let response;
        if (this.isAdmin) {
          response = await axios.get(`https://golder-31299-default-rtdb.firebaseio.com/complaints/${complaintId}.json?auth=${idToken}`);
        } else {
          response = await axios.get(`https://golder-31299-default-rtdb.firebaseio.com/user-complaints/${localId}/${complaintId}.json?auth=${idToken}`);
        }

        this.complaint = response.data;
        this.scrollToBottom();
      } catch (error) {
        console.error('Error fetching complaint details:', error);
        alert('Şikayet detayları getirilirken bir hata oluştu.');
      }
    },
    async sendResponse() {
      try {
        const idToken = await this.$store.dispatch('getToken');
        const complaintId = this.$route.params.complaintId;
        const localId = localStorage.getItem('localId');
        const userResponse = await axios.get(`https://golder-31299-default-rtdb.firebaseio.com/users/${localId}.json?auth=${idToken}`);
        const sender = this.isAdmin ? 'admin' : `${userResponse.data.name} ${userResponse.data.surname}`;
        
        const response = {
          sender,
          text: this.responseText,
          timestamp: new Date().toISOString(),
        };

        // Hem `complaints` hem de `user-complaints` koleksiyonuna yanıtı yazın
        await axios.post(`https://golder-31299-default-rtdb.firebaseio.com/complaints/${complaintId}/responses.json?auth=${idToken}`, response);
        await axios.post(`https://golder-31299-default-rtdb.firebaseio.com/user-complaints/${this.complaint.userId}/${complaintId}/responses.json?auth=${idToken}`, response);

        this.responseText = '';
        await this.fetchComplaintDetails();
      } catch (error) {
        console.error('Error sending response:', error);
        alert('Mesaj gönderilirken bir hata oluştu.');
      }
    },
    async closeComplaint() {
      try {
        const idToken = await this.$store.dispatch('getToken');
        const complaintId = this.$route.params.complaintId;

        await axios.patch(`https://golder-31299-default-rtdb.firebaseio.com/complaints/${complaintId}.json?auth=${idToken}`, { status: 'closed' });
        await axios.patch(`https://golder-31299-default-rtdb.firebaseio.com/user-complaints/${this.complaint.userId}/${complaintId}.json?auth=${idToken}`, { status: 'closed' });

        await this.fetchComplaintDetails();
      } catch (error) {
        console.error('Error closing complaint:', error);
        alert('Şikayet kapatılırken bir hata oluştu.');
      }
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const chatContainer = this.$refs.chatContainer;
        chatContainer.scrollTop = chatContainer.scrollHeight;
      });
    }
  },
  async created() {
    await this.fetchComplaintDetails();
  },
  watch: {
    'complaint.responses': function() {
      this.scrollToBottom();
    }
  }
};
</script>

<style scoped>
.container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h1, h2 {
  color: #343a40;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  text-align: center;
}

p {
  font-size: 16px;
  color: #495057;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 10px;
}

textarea {
  width: 96%;
  padding: 10px;
  font-size: 14px;
  border: 0.2px solid #4169E1;
  border-radius: 4px;
  margin-bottom: 10px;
  font-family: 'Roboto', sans-serif;
  height: 20px;
}

button {
  padding: 10px 20px;
  background-color: #4169E1;
  color: #fff;
  border: none;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  transition: background-color 0.3s;
  display: block;
  margin: 10px auto;
}

button:hover {
  background-color: #FFA500;
}

.chat-container {
  margin-top: 20px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px #4169e161;
  max-height: 400px;
  overflow-y: auto;
  height: 200px;
  margin-bottom: 10px;
}

.chat-message {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f1f3f5;
  border-radius: 4px;
  font-family: 'Roboto', sans-serif;
}

.chat-message.admin {
  background-color: #ffa60081; /* Hafif mor renkte arkaplan */
  color: #4a148c; /* Koyu mor renkte yazı */
  border: 0.2px solid #4a148c; /* Koyu mor renkte kenarlık */
}

.chat-message strong {
  display: block;
  margin-bottom: 5px;
}
.buttonRefresh {
    background-color: white;
    color: #4169E1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 20px;
    border: 0.5px solid #FFA500;
    transition: ease 0.5s background;
    width: 10%;
}
.buttonRefresh:hover {
    background-color: #FFA500;
    cursor: pointer;
}

@media (max-width: 600px) {
  .container {
    padding: 15px;
  }

  h1, h2 {
    font-size: 1.5rem;
  }

  p, textarea {
    font-size: 14px;
  }

  button {
    padding: 8px 16px;
    font-size: 14px;
  }
}
</style>
<template>
    <div class="container">
        <div class="loginCard">
            <div class="sections">
                <div class="titles">Ad</div>
                <input class="input" v-model="user.name" type="text">
            </div>
            <div class="sections">
                <div class="titles">Soyad</div>
                <input class="input" v-model="user.surname" type="text">
            </div>
            <div class="sections">
                <div class="titles">Hesap Kodu</div>
                <input class="input" v-model="user.code" type="text">
                <div v-if="codeExists" class="alerts">Hesap kodu zaten kullanılıyor!</div>
            </div>
            <div class="sections">
                <div class="titles">Telefon Numarası</div>
                <input class="input" v-model="user.number" type="text">
                <div class="alerts fornumber none">Uygun format : 554 554 5454</div>
                <div v-if="numberExists" class="alerts">Bu telefon numarası zaten kullanılıyor!</div>
            </div>
            <div class="sections">
                <div class="titles">E-mail</div>
                <input class="input" v-model="user.email" type="text">
                <div v-if="emailExists" class="alerts">Mail adresi zaten kullanılıyor!</div>
            </div>
            <div class="sections">
                <div class="titles">Şifre</div>
                <input class="input" v-model="user.password" type="password">
            </div>
            <div class="sections">
                <div class="titles">Tekrar Şifre</div>
                <input v-model="user.repassword" class="input" type="password">
                <div class="alerts forrepass none">Şifreler eşleşmiyor.</div>
                <div class="alerts forpasspower none">Şifreniz yeterince güçlü değil.</div>
            </div>
            <div class="sectionsAgree">
                <input type="checkbox" v-model="user.agreedToTerms">
                <label class="terms" for="terms"><button class="aggreeButton" @click="openAggree" >Kullanıcı sözleşmesini</button>okudum, inceledim ve kabul ediyorum.</label>
            </div>
            <div class="sectionsHelp">
                <a class="sectionsHelp-x" href="">Zaten bir hesabınız var mı?</a>
            </div>
            <div class="alertInfo">
                Kayıt olduktan sonra hesabınızı onaylatmanız gerekecek. Lütfen kayıt işlemini tamamladıktan sonra gerekli adımları takip edin.
            </div>
            <div class="sectionsBtn">
                <button class="sectionsBtn-x" @click="signUp">Kayıt ol</button>
            </div>
        </div>
        <div v-if="showModal" class="modal">
          <div class="modal-content">
            <terms-of-services></terms-of-services>
            <button class="sectionsBtn-q" @click="closeAggre">Kabul ediyorum.</button>
          </div>
        </div>
    </div>
</template>

<script>
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import axios from 'axios';
import TermsOfService from "../TermsOfService.vue";

export default {
    name: 'SignUp',
    components : {
        TermsOfServices : TermsOfService
    },
    data() {
        return {
            user: {
                email: '',
                password: '',
                repassword: '',
                name: '',
                surname: '',
                code: '',
                number: '',
                agreedToTerms : null,
                userNew : true
            },
            emailExists : false,
            codeExists : false,
            numberExists : false,
            showModal : false
        }
    },
    methods: {
        async signUp() {
            if(this.user.agreedToTerms === true){
                if (this.user.password.length > 7) {
                if (this.user.number.length === 10 && this.user.number[0] === '5') {
                    if (this.user.password === this.user.repassword) {
                        try {
                            this.codeExists = false
                            this.emailExists = false
                            this.numberExists = false
                            const functions = getFunctions();
                            const checkUserExists = httpsCallable(functions, 'checkUserExists');
                            const result = await checkUserExists({
                                email: this.user.email,
                                code: this.user.code,
                                number: this.user.number
                            });

                            const { emailExists, codeExists, numberExists } = result.data;

                            this.codeExists = codeExists
                            this.emailExists = emailExists
                            this.numberExists = numberExists

                            if (!emailExists && !codeExists && !numberExists) {
                                const auth = getAuth();
                                const userCredential = await createUserWithEmailAndPassword(auth, this.user.email, this.user.password);
                                const userId = userCredential.user.uid;
                                const idToken = await userCredential.user.getIdToken();

                                await axios.put(`https://golder-31299-default-rtdb.firebaseio.com/users/${userId}.json?auth=${idToken}`, {
                                    localId: userId,
                                    email: this.user.email,
                                    name: this.user.name,
                                    code: this.user.code,
                                    surname: this.user.surname,
                                    number: this.user.number,
                                    isAdmin: false, // Yeni kullanıcı admin değildir
                                    reqState: false,
                                    emailVerified : false,
                                    userNew : true
                                });

                                await axios.put(`https://golder-31299-default-rtdb.firebaseio.com/codes/${userId}.json?auth=${idToken}`, {
                                    code: this.user.code,
                                    verified: false,
                                    localId : userId
                                });

                                this.$router.push('/signin');
                            }
                        } catch (error) {
                            console.error('Sign-up error:', error);
                            this.$store.commit('setError', error);
                        }
                    } else {
                        document.querySelector('.forrepass').classList.remove('none');
                    }
                } else {
                    document.querySelector('.fornumber').classList.remove('none');
                }
            } else {
                document.querySelector('.forpasspower').classList.remove('none');
            }
            }else {
                document.querySelector('.terms').classList.add('redTerms')
            }  
        },
        openAggree(){
            this.showModal = true
        },
        closeAggre(){
            this.showModal = false,
            this.user.agreedToTerms = true
        }
    }
};
</script>

<style scoped>
.container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f0f7ff;
    min-height: 100vh;
}

.loginCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 400px;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 6px #4169e159;
    margin-bottom: 60px;
    margin-top: 10px;
}

.sections {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 0;
}
.sectionsAgree {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px 0;
}

.titles {
    font-size: 14px;
    font-weight: 600;
    color: #495057;
    margin-bottom: 8px;
}

.input {
    padding: 10px;
    font-size: 14px;
    border: 0.4px solid #4169E1;
    border-radius: 6px;
    transition: border-color 0.3s;
}

.terms {
    font-size: 12px;
}

.input:focus {
    border: 1px solid #4169E1;
    outline: none;
}

.sectionsBtn {
    width: 100%;
    padding: 20px 0;
}

.sectionsBtn-x {
    width: 100%;
    padding: 12px;
    background-color: #4169E1;
    border-radius: 6px;
    border: none;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s;
}

.sectionsBtn-x:hover {
    background-color: #FFA500;
}

.sectionsBtn-q {
    width: 50%;
    padding: 12px;
    background-color: #4169E1;
    border-radius: 6px;
    border: none;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s;
}

.sectionsBtn-q:hover {
    background-color: #FFA500;
}

.sectionsHelp {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin: 10px 0;
}

.sectionsHelp-x {
    font-size: 12px;
    color: #4169E1;
    text-decoration: none;
    transition: color 0.3s;
}

.sectionsHelp-x:hover {
    color: #4169e181;
}

.alertInfo {
    font-size: 12px;
    color: #dc3545;
    text-align: center;
    margin-bottom: 10px;
}

.errorLogin {
    font-size: 14px;
    color: #dc3545;
    margin-bottom: 10px;
    text-align: center;
}

.alerts {
    font-size: 12px;
    color: #dc3545;
    margin-top: 5px;
}

.none {
    display: none;
}

.redTerms {
    color : #dc3545;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 6px #ffa6007c;
  width: 90%;
  max-width: 500px;
  max-height: 500px;
  overflow: auto;
  text-align: center;
  position: relative;
}

.aggreeButton {
    border : 0px;
    background-color: transparent;
    font-size: 12px;
    color: #4169E1;
}

.aggreeButton:hover {
    cursor: pointer;
    text-decoration: underline;
}

@media (max-width: 768px) {
    .loginCard {
        padding: 15px;
        max-width: 90%;
    }

    .titles {
        font-size: 12px;
    }

    .input {
        padding: 8px;
        font-size: 12px;
    }

    .sectionsBtn-x {
        padding: 10px;
        font-size: 14px;
    }

    .sectionsHelp-x {
        font-size: 10px;
    }

    .alertInfo {
        font-size: 10px;
    }

    .alerts {
        font-size: 10px;
    }
}
</style>
import { initializeApp } from "firebase/app";
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber, sendEmailVerification } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyB5n1o5KycWbwsCHFeCMUf2NIE1PAtX7eU",
    authDomain: "golder-31299.firebaseapp.com",
    databaseURL: "https://golder-31299-default-rtdb.firebaseio.com",
    projectId: "golder-31299",
    storageBucket: "golder-31299.appspot.com",
    messagingSenderId: "788648734445",
    appId: "1:788648734445:web:dabdd6feb5aad46e5e66e4"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth, RecaptchaVerifier, signInWithPhoneNumber, sendEmailVerification };

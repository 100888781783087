<template>
  <div class="container">
    <h3>Destek Taleplerim</h3>
    <div class="table-container">
      <table>
        <thead>
          <tr>
            <th>Şikayet ID</th>
            <th>Konu</th>
            <th>Durum</th>
            <th>İşlemler</th>
            <th>Tarih</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="complaint in sortedComplaints" :key="complaint.id">
            <td>{{ complaint.id }}</td>
            <td>{{ complaint.text }}</td>
            <td>{{ complaint.status === 'closed' ? 'Kapatıldı' : 'Açık'}}</td>
            <td>{{ formatDate(complaint.timestamp)}}</td>
            <td>
              <button @click="viewComplaint(complaint.id)" class="view-btn">Görüntüle</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <textarea v-model="newComplaint" placeholder="Şikayetinizin konusunu buraya yazın ardından açılan şikayet sohbetinize açıklamanızı yazınız."></textarea>
    <button @click="checkOpenComplaints" class="submit-btn">Oluştur</button>

    <div v-if="showBanModal" class="ban-modal">
      <div class="ban-modal-content">
        <h2>Hesap Banlı</h2>
        <p>Hesabınız banlı durumda. Lütfen destek ile iletişime geçin.</p>
        <button @click="logout" class="ban-btn">Çıkış Yap</button>
      </div>
    </div>

    <div v-if="showOpenComplaintModal" class="open-complaint-modal">
      <div class="open-complaint-modal-content">
        <h2>Şikayet Oluşturulamadı</h2>
        <p>Henüz açık durumda bir şikayetiniz mevcut. Lütfen mevcut şikayetinizin durumunu kontrol edin.</p>
        <button @click="closeOpenComplaintModal" class="open-complaint-btn">Tamam</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

export default {
  data() {
    return {
      userComplaints: [],
      newComplaint: '',
      showBanModal: false,
      showOpenComplaintModal: false
    };
  },
  computed : {
    sortedComplaints() {
          return this.userComplaints.slice().sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
      }
  },
  methods: {
    async fetchUserComplaints() {
      const idToken = await this.$store.dispatch('getToken');
      const localId = localStorage.getItem('localId');
      const response = await axios.get(`https://golder-31299-default-rtdb.firebaseio.com/user-complaints/${localId}.json?auth=${idToken}`);
      const complaintsData = response.data;
      this.userComplaints = complaintsData ? Object.keys(complaintsData).map(key => ({ id: key, ...complaintsData[key] })) : [];
    },
    async checkOpenComplaints() {
      const openComplaints = this.userComplaints.some(complaint => complaint.status === 'open');
      if (openComplaints) {
        this.showOpenComplaintModal = true;
      } else {
        this.submitComplaint();
      }
    },
    async submitComplaint() {
      const idToken = await this.$store.dispatch('getToken');
      const localId = localStorage.getItem('localId');
      const complaintId = uuidv4();
      const complaintData = {
        userId: localId,
        text: this.newComplaint,
        status: 'open',
        timestamp: new Date().toISOString(),
      };
      try {
        await axios.put(`https://golder-31299-default-rtdb.firebaseio.com/user-complaints/${localId}/${complaintId}.json?auth=${idToken}`, complaintData);
        await axios.put(`https://golder-31299-default-rtdb.firebaseio.com/complaints/${complaintId}.json?auth=${idToken}`, complaintData);
        this.newComplaint = '';
        this.fetchUserComplaints();
      } catch (error) {
        console.error(error);
        alert('Şikayet gönderilirken bir hata oluştu.');
      }
    },
    viewComplaint(complaintId) {
      this.$router.push(`/complaint/${complaintId}`);
    },
    async checkBanStatus() {
      const idToken = await this.$store.dispatch('getToken');
      const localId = localStorage.getItem('localId');
      const response = await axios.get(`https://golder-31299-default-rtdb.firebaseio.com/users/${localId}.json?auth=${idToken}`);
      if (response.data.isBanned) {
        this.showBanModal = true;
      }
    },
    logout() {
      localStorage.removeItem('localId');
      this.$store.dispatch("logout");
      this.$router.push('/signin');
    },
    closeOpenComplaintModal() {
      this.showOpenComplaintModal = false;
    },
    formatDate(dateString) {
            const date = new Date(dateString);
            return date.toLocaleString();
        },
  },
  async created() {
    await this.checkBanStatus();
    if (!this.showBanModal) {
      this.fetchUserComplaints();
    }
  },
};
</script>

<style scoped>
.container {
  padding: 20px;
  background-color: #f0f7ff;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
}

h1, h2 {
  color: #212529;
  font-weight: 700;
}

.table-container {
  margin-bottom: 20px;
  max-height: 400px;
  overflow: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px #4169e15e;
}

thead {
  background-color: #4169E1;
  color: #ffffff;
}

th, td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #dee2e6;
}

th {
  font-size: 15px;
  font-weight: 600;
}

td {
  font-size: 14px;
}

textarea {
  width: 100%;
  padding: 12px;
  font-size: 14px;
  border: 1px solid #ced4da;
  border-radius: 6px;
  margin-bottom: 10px;
  resize: vertical;
  box-shadow: inset 0 1px 2px #4169e15f;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-btn {
  background-color: #4169E1;
  color: #fff;
}

.submit-btn:hover {
  background-color: #FFA500;
}

.view-btn {
  background-color: #4169E1;
  color: #fff;
}

.view-btn:hover {
  background-color: #FFA500;
}

/* Ban Modal styles */
.ban-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.ban-modal-content {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 500px;
  text-align: center;
  position: relative;
}

.ban-btn {
  padding: 10px 20px;
  background-color: #dc3545;
  color: #ffffff;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.ban-btn:hover {
  background-color: #c82333;
}

.open-complaint-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.open-complaint-modal-content {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 500px;
  text-align: center;
  position: relative;
}

.open-complaint-btn {
  padding: 10px 20px;
  background-color: #4169E1;
  color: #ffffff;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.open-complaint-btn:hover {
  background-color: #FFA500;
}
</style>

<template>
    <div :class="['bottom-header', { 'bottom-header-visible': isVisible }]">
      <div class="bottom-nav">
        <router-link class="bottom-nav-item" :class="navClass" to="/about">Hakkımızda</router-link>
        <router-link class="bottom-nav-item" :class="navClass" to="/how">Nasıl kullanılır?</router-link>
        <router-link class="bottom-nav-item" :class="navClass" to="/terms">Kullanım Şartları</router-link>
      </div>
    </div>
</template>

<script>
export default {
  name: 'BottomHeader',
  data() {
    return {
      isVisible: false,
      lastScrollTop: 0,
    };
  },
  computed: {
    navClass() {
      return {
        "active-nav": this.$route.path === this.to,
      };
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() { // beforeDestroy yerine beforeUnmount kullanılıyor
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > this.lastScrollTop) {
        // Aşağı kaydırma
        this.isVisible = true;
      } else {
        // Yukarı kaydırma
        this.isVisible = false;
      }
      this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // Negatif scroll hatasını önler
    }
  }
};
</script>

<style scoped>
.bottom-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 10px;
  background: linear-gradient(to top, #FFA500, #f0f7ff);
  color: white;
  position: fixed;
  width: 100%;
  bottom: -100px;
  left: 0;
  z-index: 1000;
  font-family: 'Roboto', sans-serif;
  transition: bottom 0.3s;
}

.bottom-header-visible {
  bottom: 0;
}

.bottom-nav {
  display: flex;
  align-items: center;
}

.bottom-nav-item {
  margin: 0 15px;
  font-size: 12px;
  font-weight: bold;
  color: #ffffff9e;
  text-decoration: none;
  padding: 10px 15px;
  border-radius: 6px;
  transition: font-size 0.3s, color 0.3s;
}

.bottom-nav-item:hover,
.bottom-nav-item.active-nav {
  color: #ffffff;
  font-size: 12px;
}
</style>
<template>
    <div class="container" v-if="isAdmin">
        <h1>Satıcı Ekle</h1>
        <div class="addTrader">
            <input class="inputTrader" v-model="traderId" placeholder="Kullanıcı ID">
            <input class="inputTrader" v-model="traderNick" placeholder="Görünecek Takma Ad">
            <button class="buttonTrader" @click="addTrader">Ekle</button>
        </div>
        <div class="changeCode">
            <h1>Kullanıcının hesap kodu onayı için göreceği kod:</h1>
            <input class="payInfo" v-model="payInfo.payCode" type="text">
            <button class="payButton" @click="updatePayInfo">Değiş</button>
        </div>
        <h1>Bekleyen Onay İstekleri</h1>
        <div @click="fetchRequests" class="buttonRefresh">
            <i class="fas fa-sync-alt"></i>
        </div>
        <div class="scrollCode">
            <table>
                <thead>
                    <tr>
                        <th>Kullanıcı ID</th>
                        <th class="none">Email</th>
                        <th>Hesap Kodu</th>
                        <th>Durum</th>
                        <th>İşlemler</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(request, index) in visibleRequests" :key="request.id" :class="{'gray-background': index % 2 === 0, 'white-background': index % 2 !== 0}">
                        <td>{{ request.userId }}</td>
                        <td class="none">{{ request.email }}</td>
                        <td>{{ request.code }}</td>
                        <td :class="getStatusClass(request.status)">
                            {{ request.status === 'approved' ? 'Onaylandı' : request.status === 'rejected' ? 'Reddedildi' : 'Bekliyor' }}
                        </td>
                        <td>
                            <button v-if="request.status === 'pending'" @click="verifyRequest(request.id, request.userId)">Onayla</button>
                            <button class="redClass" v-if="request.status === 'pending'" @click="rejectRequest(request.id, request.userId)">Reddet</button>
                            <button @click="hideRequest(request.id)">Sil</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        

        <h1>Alış Siparişleri</h1>
        <div @click="fetchOrders" class="buttonRefresh">
            <i class="fas fa-sync-alt"></i>
        </div>
        <div class="scrollCode">
            <table class="scroll">
                <thead>
                    <tr>
                        <th> Tarih </th>
                        <th class="none">Sipariş ID</th>
                        <th class="none">Kullanıcı ID</th>
                        <th class="none">Email</th>
                        <th class="none">Satıcı</th>
                        <th>Hesap Kodu</th>
                        <th>IBAN</th>
                        <th>Gold Miktarı</th>
                        <th>Fiyat</th>
                        <th>Durum</th>
                        <th>İşlemler</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(order, index) in sortedBuy" :key="order.orderId" :class="{'gray-background': index % 2 === 0, 'white-background': index % 2 !== 0}">
                        <td>{{ formatDate(order.date) }}</td>
                        <td class="none">{{ order.orderId }}</td>
                        <td class="none">{{ order.userId }}</td>
                        <td class="none">{{ order.email }}</td>
                        <td class="none">{{ order.trader.traderNick }}</td>
                        <td>{{ order.code }}</td>
                        <td class="iban">{{ order.iban }}</td>
                        <td>{{ order.goldAmount }}M</td>
                        <td>{{ order.price }} TL</td>
                        <td :class="getStatusClass(order.status)">
                            {{ order.status === 'approved' ? 'Onaylandı' : order.status === 'rejected' ? 'Reddedildi' : 'Bekliyor' }}
                        </td>
                        <td>
                            <button v-if="order.status === 'pending'" @click="verifyOrder(order.orderId, order.userId, 'buy', order.trader.userId)">Onayla</button>
                            <button class="redClass" v-if="order.status === 'pending'" @click="rejectOrder(order.orderId, order.userId, 'buy', order.trader.userId)">Reddet</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        

        <h1>Satış Siparişleri</h1>
        <div @click="fetchOrders" class="buttonRefresh">
            <i class="fas fa-sync-alt"></i>
        </div>
        <div class="scrollCode">
            <table>
                <thead>
                    <tr>
                        <th> Tarih </th>
                        <th class="none">Sipariş ID</th>
                        <th class="none">Kullanıcı ID</th>
                        <th class="none">Email</th>
                        <th class="none">Satıcı</th>
                        <th>Hesap Kodu</th>
                        <th>IBAN</th>
                        <th>Gold Miktarı</th>
                        <th>Fiyat</th>
                        <th>Durum</th>
                        <th>İşlemler</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(order, index) in sortedSell" :key="order.orderId" :class="{'gray-background': index % 2 === 0, 'white-background': index % 2 !== 0}">
                        <td>{{ formatDate(order.date) }}</td>
                        <td>{{ order.orderId }}</td>
                        <td>{{ order.userId }}</td>
                        <td>{{ order.email }}</td>
                        <td class="none">{{ order.trader.traderNick }}</td>
                        <td>{{ order.code }}</td>
                        <td>{{ order.iban }}</td>
                        <td>{{ order.goldAmount }}M</td>
                        <td>{{ order.price }} TL</td>
                        <td :class="getStatusClass(order.status)">
                            {{ order.status === 'approved' ? 'Onaylandı' : order.status === 'rejected' ? 'Reddedildi' : 'Bekliyor' }}
                        </td>
                        <td>
                            <button v-if="order.status === 'pending'" @click="verifyOrder(order.orderId, order.userId, 'sell', order.trader.userId)">Onayla</button>
                            <button class="redClass" v-if="order.status === 'pending'" @click="rejectOrder(order.orderId, order.userId, 'sell', order.trader.userId)">Reddet</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        

        <h1>Destek Şikayetleri</h1>
        <div @click="fetchComplaints" class="buttonRefresh">
            <i class="fas fa-sync-alt"></i>
        </div>
        <div class="scrollCode">
            <table>
                <thead>
                    <tr>
                        <th class="none">Şikayet ID</th>
                        <th class="none">Kullanıcı ID</th>
                        <th>Şikayet Konusu</th>
                        <th>Durum</th>
                        <th>Tarih</th>
                        <th>İşlemler</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(complaint, index) in sortedComplaints" :key="complaint.id" :class="{'gray-background': index % 2 === 0, 'white-background': index % 2 !== 0}">
                        <td class="none">{{ complaint.id }}</td>
                        <td class="none">{{ complaint.userId }}</td>
                        <td>{{ complaint.text }}</td>
                        <td>{{ complaint.status === 'closed' ? 'Kapatıldı' : 'Açık'}}</td>
                        <td>{{ formatDate(complaint.timestamp) }}</td>
                        <td>
                            <button @click="viewComplaint(complaint.id)">Detay</button>
                            <button class="redClass" @click="closeComplaint(complaint.id, complaint.userId)">Kapat</button>
                            <button @click="hideComplaint(complaint.id, complaint.userId)">Sil</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <h1>Kullanıcı Bilgileri</h1>
        <div class="user-info-section">
            <input class="inputId" type="text" v-model="searchUserId" placeholder="Kullanıcı ID Girin">
            <button @click="fetchUserInfo">Kullanıcıyı Getir</button>
        </div>
        <div class="userInfo" v-if="userInfo">
            <h2>Kullanıcı ID: {{ userInfo.id }}</h2>
            <label>Ad: <input class="inputUser" type="text" v-model="userInfo.name"></label>
            <label>Soyad: <input class="inputUser" type="text" v-model="userInfo.surname"></label>
            <label>Email: <input class="inputUser" type="text" v-model="userInfo.email"></label>
            <label>Telefon: <input class="inputUser" type="text" v-model="userInfo.number"></label>
            <label>Hesap Kodu: <input class="inputUser" type="text" v-model="userInfo.code"></label>
            <label>IBAN: <input class="inputUser" type="text" v-model="userInfo.iban"></label>
            <label v-if="userInfo.isTrader">Satıcı Nick: <input class="inputUser" type="text" v-model="userInfo.traderInfo.traderNick"></label>
            <label>Onaylı: <input class="inputUser" type="checkbox" v-model="userInfo.isVerified"></label>
            <div class="user-info-buttons">
                <button @click="updateUserInfo">Bilgileri Güncelle</button>
                <button @click="deleteUser">Kullanıcıyı Banla</button>
            </div>
        </div>
    </div>
    <div v-else>
        <p>Bu sayfaya erişim izniniz yok.</p>
    </div>
</template>

<script>

    import axios from 'axios';

export default {
    name: 'AdminPanel',
    data() {
        return {
            requests: [],
            buyOrders: [],
            sellOrders: [],
            complaints: [],
            searchUserId: '',
            userInfo: null,
            isAdmin: false,
            payInfo : {},
            traderId: '',
            traderNick : '',
            darkLists : {}
        };
    },
    computed: {
        visibleRequests() {
            return this.requests.filter(request => !request.hidden);
        },
        visibleComplaints() {
            return this.complaints.filter(complaint => !complaint.hidden);
        },
        sortedBuy() {
            return this.buyOrders.slice().sort((a, b) => new Date(b.date) - new Date(a.date));
        },
        sortedSell() {
            return this.sellOrders.slice().sort((a, b) => new Date(b.date) - new Date(a.date));
        },
        sortedComplaints() {
            return this.visibleComplaints.slice().sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
        },
    },
    methods: {
        async fetchRequests() {
            const idToken = await this.$store.dispatch('getToken');
            const response = await axios.get('https://golder-31299-default-rtdb.firebaseio.com/verificationRequests.json?auth=' + idToken);
            const requestsData = response.data;
            this.requests = requestsData ? Object.keys(requestsData).map(key => ({ id: key, ...requestsData[key] })) : [];
        },
        async fetchOrders() {
            const idToken = await this.$store.dispatch('getToken');
            try {
                const buyResponse = await axios.get('https://golder-31299-default-rtdb.firebaseio.com/orders.json?auth=' + idToken);
                const buyOrdersData = buyResponse.data;
                if (buyOrdersData) {
                    this.buyOrders = Object.keys(buyOrdersData).map(key => ({ orderId: key, ...buyOrdersData[key] }));
                } else {
                    this.buyOrders = [];
                }

                const sellResponse = await axios.get('https://golder-31299-default-rtdb.firebaseio.com/sales.json?auth=' + idToken);
                const sellOrdersData = sellResponse.data;
                if (sellOrdersData) {
                    this.sellOrders = Object.keys(sellOrdersData).map(key => ({ orderId: key, ...sellOrdersData[key] }));
                } else {
                    this.sellOrders = [];
                }
            } catch (error) {
                console.error('Siparişler getirilirken hata oluştu:', error);
            }
        },
        async fetchComplaints() {
            const idToken = await this.$store.dispatch('getToken');
            const response = await axios.get('https://golder-31299-default-rtdb.firebaseio.com/complaints.json?auth=' + idToken);
            const complaintsData = response.data;
            this.complaints = complaintsData ? Object.keys(complaintsData).map(key => ({ id: key, ...complaintsData[key] })) : [];
        },
        async verifyRequest(requestId, userId) {
            const idToken = await this.$store.dispatch('getToken');
            try {
                await axios.patch(`https://golder-31299-default-rtdb.firebaseio.com/users/${userId}.json?auth=${idToken}`, { isVerified: true, reqState: false });
                await axios.patch(`https://golder-31299-default-rtdb.firebaseio.com/codes/${userId}.json?auth=${idToken}`, { verified: true });
                await axios.patch(`https://golder-31299-default-rtdb.firebaseio.com/verificationRequests/${requestId}.json?auth=${idToken}`, { status: 'approved' });
                this.fetchRequests();
            } catch (error) {
                console.error('Onaylama işlemi sırasında hata oluştu:', error);
            }
        },
        async rejectRequest(requestId, userId) {
            const idToken = await this.$store.dispatch('getToken');
            try {
                await axios.patch(`https://golder-31299-default-rtdb.firebaseio.com/users/${userId}.json?auth=${idToken}`, { isVerified: false, code: '', isRejected: true });
                await axios.patch(`https://golder-31299-default-rtdb.firebaseio.com/codes/${userId}.json?auth=${idToken}`, { verified: false, code: '' });
                await axios.patch(`https://golder-31299-default-rtdb.firebaseio.com/users/${userId}.json?auth=${idToken}`, {
                    reqState : false
                })
                await axios.patch(`https://golder-31299-default-rtdb.firebaseio.com/verificationRequests/${requestId}.json?auth=${idToken}`, { status: 'rejected' });
                this.fetchRequests();
            } catch (error) {
                console.error('Reddetme işlemi sırasında hata oluştu:', error);
            }
        },
        async verifyOrder(orderId, userId, type, traderId) {
            const idToken = await this.$store.dispatch('getToken');
            try {
                const path = type === 'buy' ? 'orders' : 'sales';
                await axios.patch(`https://golder-31299-default-rtdb.firebaseio.com/${path}/${orderId}.json?auth=${idToken}`, { status: 'approved' });
                await axios.patch(`https://golder-31299-default-rtdb.firebaseio.com/user-${path}/${userId}/${orderId}.json?auth=${idToken}`, { status: 'approved' });
                await axios.patch(`https://golder-31299-default-rtdb.firebaseio.com/trader-${path}/${traderId}/${orderId}.json?auth=${idToken}`, { status: 'approved' });
                this.fetchOrders();
            } catch (error) {
                console.error('Onaylama işlemi sırasında hata oluştu:', error);
            }
        },
        async rejectOrder(orderId, userId, type, traderId) {
            const idToken = await this.$store.dispatch('getToken');
            try {
                const path = type === 'buy' ? 'orders' : 'sales';
                await axios.patch(`https://golder-31299-default-rtdb.firebaseio.com/${path}/${orderId}.json?auth=${idToken}`, { status: 'rejected' });
                await axios.patch(`https://golder-31299-default-rtdb.firebaseio.com/user-${path}/${userId}/${orderId}.json?auth=${idToken}`, { status: 'rejected' });
                await axios.patch(`https://golder-31299-default-rtdb.firebaseio.com/trader-${path}/${traderId}/${orderId}.json?auth=${idToken}`, { status: 'rejected' });
                this.fetchOrders();
            } catch (error) {
                console.error('Reddetme işlemi sırasında hata oluştu:', error);
            }
        },
        async hideRequest(requestId) {
            const idToken = await this.$store.dispatch('getToken');
            try {
                await axios.patch(`https://golder-31299-default-rtdb.firebaseio.com/verificationRequests/${requestId}.json?auth=${idToken}`, { hidden: true });
                this.fetchRequests();
            } catch (error) {
                console.error('İstek gizlenirken hata oluştu:', error);
            }
        },
        async hideComplaint(complaintId,userId) {
            const idToken = await this.$store.dispatch('getToken');
            try {
                await axios.patch(`https://golder-31299-default-rtdb.firebaseio.com/complaints/${complaintId}.json?auth=${idToken}`, { hidden: true, status : 'closed' });
                await axios.patch(`https://golder-31299-default-rtdb.firebaseio.com/user-complaints/${userId}/${complaintId}.json?auth=${idToken}`, { hidden: true, status : 'closed' });
                this.fetchComplaints();
            } catch (error) {
                console.error('Şikayet gizlenirken hata oluştu:', error);
            }
        },
        async fetchUserInfo() {
            const idToken = await this.$store.dispatch('getToken');
            try {
                const response = await axios.get(`https://golder-31299-default-rtdb.firebaseio.com/users/${this.searchUserId}.json?auth=${idToken}`);
                this.userInfo = { id: this.searchUserId, ...response.data };
            } catch (error) {
                console.error('Kullanıcı bilgileri getirilirken hata oluştu:', error);
            }
        },
        async updateUserInfo() {
            const idToken = await this.$store.dispatch('getToken');
            try {
                await axios.patch(`https://golder-31299-default-rtdb.firebaseio.com/users/${this.userInfo.id}.json?auth=${idToken}`, this.userInfo);
                await axios.patch(`https://golder-31299-default-rtdb.firebaseio.com/codes/${this.userInfo.id}.json?auth=${idToken}`, {
                    code : this.userInfo.code,
                    verified : this.userInfo.isVerified
                });
                await axios.patch(`https://golder-31299-default-rtdb.firebaseio.com/traders/${this.userInfo.id}.json?auth=${idToken}`, {
                    traderNick : this.userInfo.traderInfo.traderNick
                })
            } catch (error) {
                console.error('Kullanıcı bilgileri güncellenirken hata oluştu:', error);
            }
        },
        async deleteUser() {
            const idToken = await this.$store.dispatch('getToken');
            try {
                // Kullanıcının bilgilerini güncelle
                await axios.patch(`https://golder-31299-default-rtdb.firebaseio.com/users/${this.userInfo.id}.json?auth=${idToken}`, { isBanned: true });
                this.userInfo = null;
            } catch (error) {
                console.error('Kullanıcı yasaklanırken hata oluştu:', error);
            }
        },
        async closeComplaint(complaintId, userId) {
            const idToken = await this.$store.dispatch('getToken');
            try {
                await axios.patch(`https://golder-31299-default-rtdb.firebaseio.com/complaints/${complaintId}.json?auth=${idToken}`, { status: 'closed' });
                await axios.patch(`https://golder-31299-default-rtdb.firebaseio.com/user-complaints/${userId}/${complaintId}.json?auth=${idToken}`, { status: 'closed' });
                this.fetchComplaints();
            } catch (error) {
                console.error('Şikayet kapatılırken hata oluştu:', error);
            }
        },
        viewComplaint(complaintId) {
            this.$router.push(`/complaint/${complaintId}`);
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            return date.toLocaleString();
        },
        async updatePayInfo(){
            const idToken = await this.$store.dispatch('getToken');
            axios.patch(`https://golder-31299-default-rtdb.firebaseio.com/payInfo.json?auth=${idToken}`, {
                iban : this.payInfo.iban,
                payCode : this.payInfo.payCode
            })
        },
        getStatusClass(status) {
            if (status === 'approved') return 'status-approved';
            if (status === 'rejected') return 'status-rejected';
            return 'status-pending';
        },
        async addTrader(){
            const idToken = await this.$store.dispatch('getToken');
            await axios.put(`https://golder-31299-default-rtdb.firebaseio.com/traders/${this.traderId}.json?auth=${idToken}`, {
                payInfo : {
                    iban : '',
                    payCode : ''
                },
                traderNick : this.traderNick,
                userId : this.traderId
            })
            await axios.patch(`https://golder-31299-default-rtdb.firebaseio.com/users/${this.traderId}.json?auth=${idToken}`,{
                traderInfo : {
                    traderNick : this.traderNick,
                },
                isTrader : true
            })
            this.traderId = ''
            this.traderNick = ''
        }
    },
    async created() {
        const idToken = await this.$store.dispatch('getToken');
        const localId = localStorage.getItem('localId');
        const response = await axios.get(`https://golder-31299-default-rtdb.firebaseio.com/users/${localId}.json?auth=${idToken}`);
        const user = response.data;
        const res = await axios.get(`https://golder-31299-default-rtdb.firebaseio.com/payInfo.json?auth=${idToken}`)
        this.payInfo = res.data

        if (user.isAdmin) {
            this.isAdmin = true;
            this.fetchRequests();
            this.fetchOrders();
            this.fetchComplaints();
            }
            },
            };
</script>

<style scoped>
.container {
    margin: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px #4169e15f;
}
h1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #343a40;
    border-bottom: 2px solid #4169E1;
    padding-bottom: 5px;
}
h2 {
    font-size: 20px;
    margin-top: 20px;
    color: #343a40;
    border-bottom: 2px solid #4169E1;
    padding-bottom: 5px;
}
table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px #4169e160;
}
thead {
    background-color: #4169E1;
    color: #fff;
}
th, td {
    padding: 10px;
    text-align: left;
    border-bottom: 0.2px solid #4169E1;
}
th {
    font-size: 14px;
    font-weight: 600;
}
td {
    font-size: 14px;
}
button {
    padding: 6px 12px;
    background-color: #4169E1;
    border: none;
    border-radius: 4px;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-right: 5px;
    margin: 5px
}
button:hover {
    background-color: #4169e160;
}
button:last-of-type {
    background-color: #dc3545;
}
button:last-of-type:hover {
    background-color: #dc354672;
}
p {
    font-size: 16px;
    color: #dc3545;
}
.user-info-section {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 50px;
}
.user-info-section input {
    padding: 10px 20px;
    font-size: 14px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    margin-right: 10px;
    flex: 1 1 200px;
    max-width: 100%;
}
.user-info-section button {
    padding: 10px 20px;
    background-color: #4169E1;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    flex: 1 1 100px;
}
.user-info-section button:hover {
    background-color: #FFA500;
}
.user-info-section input[type="text"] {
    width: auto;
    margin: 0 10px 10px 0;
}
.user-info-buttons {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}
.user-info-buttons button {
    flex: 1 1 100px;
    max-width: 100%;
}
.input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}
.input-group label {
    font-size: 14px;
    color: #495057;
    margin-bottom: 5px;
}
.input-group input {
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ced4da;
    border-radius: 4px;
}
.payInfo {
    padding: 5px;
    width: 35%;
    margin-left: 5px
}
.payButton {
    padding: 8px;
    margin-left: 5px;
}
.scrollCode {
    max-height: 400px;
    overflow: auto;
}
.gray-background {
    background-color: #f0f0f0;
}

.white-background {
    background-color: #ffffff;
}

td.status-approved {
    color: #28a72c;
    font-weight: 600;
}

td.status-rejected {
    color: #dc3545;
    font-weight: 600;
}

td.status-pending {
    color: #FFA500;
    font-weight: 600;
}

button.payButton {
    background-color: #4169E1;
}
button.redClass {
    background-color: #FFA500;
}
button.redClass:hover {
    background-color: #ffa60079;
}
.buttonRefresh {
    background-color: white;
    color: #4169E1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 85%;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: 0.5px solid #FFA500;
    transition: ease 0.5s background;
    width: 10%;
}
.buttonRefresh:hover {
    background-color: #FFA500;
    cursor: pointer;
}
.inputTrader {
    padding: 5px;
    margin: 2px
}
.buttonTrader {
    padding: 8px;
    margin: 2px
}


@media (max-width: 1100px) {
    th, td {
        padding: 6px;
        font-size: 12px;
    }
    button {
        padding: 6px 12px;
        font-size: 12px;
    }
    h1 {
        font-size: 20px;
    }
    h2 {
        font-size: 18px;
    }
    .user-info-section {
        flex-direction: column;
    }
    button {
        width: 60px;
        margin-bottom: 10px;
        margin-top: 10px;
        padding: 10px;
    }
    .user-info-section {
        display: flex;
        flex-direction: row;
    }
    .userInfo {
        display: flex;
        flex-direction: column
    }
    label {
        padding: 5px;
    }
    .inputUser {
        padding: 5px;
    }
    .scrollCode {
    max-height: 400px;
    overflow: auto;
    }
}
</style>
<template>
    <div class="about">
      <h1>Hakkımızda</h1>
      <p>Saveh Gold, AgarZ oyununda kullanılan oyun paralarını alıp satabileceğiniz güvenilir bir platformdur. Platformumuz, kullanıcıların kimseyle muhatap olmadan kolayca alım ve satım işlemlerini gerçekleştirmesine olanak tanır.</p>
      <p>Gold satışında, kullanıcılar satmak istedikleri goldları belirtilen hesap koduna aktarırken, gold alımında ise belirtilen IBAN adresine parayı transfer ederler. Bu sayede tüm işlemler hızlı ve güvenli bir şekilde tamamlanır.</p>
      <h2>Misyonumuz</h2>
      <p>Saveh Gold olarak, oyuncuların oyun içi ihtiyaçlarını hızlı ve güvenilir bir şekilde karşılamalarını sağlamayı amaçlıyoruz. Amacımız, kullanıcılarımızın zamanını ve emeğini koruyarak, onlara en iyi hizmeti sunmaktır.</p>
      <h2>Vizyonumuz</h2>
      <p>Saveh Gold olarak, oyun dünyasında güvenilir bir isim olmayı ve oyuncuların ilk tercih ettiği platform olmayı hedefliyoruz. Kullanıcı memnuniyeti bizim için her zaman ön plandadır.</p>
      <h2>Ekibimiz</h2>
      <p>Saveh Gold, bireysel olarak geliştirilen bir proje olup, bir ekip olarak yönetilmektedir. Yazılım tamamen bize ait olup, kullanıcılarımıza en iyi hizmeti sunmak için sürekli olarak çalışmaktayız. Bu platformun AgarZ yönetimiyle hiçbir alakası bulunmamaktadır.</p>
      <h2>İletişim</h2>
      <p>Eğer herhangi bir sorunuz veya öneriniz varsa, lütfen bizimle <a href="mailto:gorkem@savehgold.com.tr">gorkem@savehgold.com.tr</a> adresinden iletişime geçin. Size yardımcı olmaktan mutluluk duyarız.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AboutUs'
  }
  </script>
  
  <style scoped>
  .about {
    padding: 20px;
    padding-bottom: 80px;
    max-width: 600px;
    margin: 0 auto;
    font-family: 'Arial', sans-serif;
    color: #333;
  }
  .about h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.8em;
    font-weight: bold;
    color: #2c3e50;
  }
  .about p {
    margin-bottom: 10px;
    line-height: 1.6;
    font-size: 0.9em;
  }
  .about h2 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 1.2em;
    color: #2980b9;
  }
  .about a {
    color: #2980b9;
    text-decoration: none;
  }
  .about a:hover {
    text-decoration: underline;
  }
  </style>
  
<template>
    <div class="container">
        <h1>Sipariş Geçmişi</h1>
        <div @click="getData" class="buttonRefresh">
            <i class="fas fa-sync-alt"></i>
        </div>
        <div v-if="combinedOrders.length" class="table-container">
            <table>
                <thead>
                    <tr>
                        <th>Sipariş ID</th>
                        <th>Gold Miktarı</th>
                        <th>Fiyat</th>
                        <th>Durum</th>
                        <th>Tarih</th>
                        <th>Tür</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="order in combinedOrders" :key="order.id">
                        <td>{{ order.id }}</td>
                        <td>{{ order.goldAmount }} milyon</td>
                        <td>{{ order.price }} TL</td>
                        <td :class="getStatusClass(order.status)">
                            {{ order.status === 'approved' ? 'Onaylandı' : order.status === 'rejected' ? 'Reddedildi' : 'Bekliyor' }}
                        </td>
                        <td>{{ formatDate(order.date) }}</td>
                        <td>{{ order.type === 'purchase' ? 'Alış' : 'Satış' }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <p v-else class="no-orders-message">Henüz bir siparişiniz yok.</p>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            orders: [],
            sales: [],
        };
    },
    computed: {
        combinedOrders() {
            const formattedOrders = this.orders.map(order => ({ ...order, type: 'purchase', id: order.orderId }));
            const formattedSales = this.sales.map(sale => ({ ...sale, type: 'sale', id: sale.saleId }));
            const allOrders = [...formattedOrders, ...formattedSales];

            // Siparişleri tarihe göre en yeniden en eskiye sıralama
            return allOrders.sort((a, b) => new Date(b.date) - new Date(a.date));
        }
    },
    methods: {
        async fetchOrders() {
            const localId = localStorage.getItem('localId');
            const idToken = await this.$store.dispatch('getToken');
            const response = await axios.get(`https://golder-31299-default-rtdb.firebaseio.com/user-orders/${localId}.json?auth=${idToken}`);
            const ordersData = response.data;
            this.orders = ordersData ? Object.keys(ordersData).map(key => ({ orderId: key, ...ordersData[key] })) : [];
        },
        async fetchSales() {
            const localId = localStorage.getItem('localId');
            const idToken = await this.$store.dispatch('getToken');
            const response = await axios.get(`https://golder-31299-default-rtdb.firebaseio.com/user-sales/${localId}.json?auth=${idToken}`);
            const salesData = response.data;
            this.sales = salesData ? Object.keys(salesData).map(key => ({ saleId: key, ...salesData[key] })) : [];
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            return isNaN(date) ? 'Geçersiz Tarih' : date.toLocaleString();
        },
        getStatusClass(status) {
            if (status === 'approved') return 'status-approved';
            if (status === 'rejected') return 'status-rejected';
            return 'status-pending';
        },
        getData(){
            this.fetchOrders()
            this.fetchSales()
        }
    },
    async created() {
        await this.fetchOrders();
        await this.fetchSales();
    }
};
</script>

<style scoped>
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #f0f7ff;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

h1 {
    color: #212529;
    margin-bottom: 20px;
}

.table-container {
    width: 100%;
    max-width: 900px;
    max-height: 500px;
    margin-bottom: 20px;
    border-radius: 8px;
    overflow: auto;
    box-shadow: 0 4px 6px #4169e161;
    background-color: #ffffff;
}

table {
    width: 100%;
    border-collapse: collapse;
}

thead {
    background-color: #4169E1;
    color: #ffffff;
}

th, td {
    padding: 12px;
    text-align: left;
    border-bottom: 0.2px solid #4169E1;
}

th {
    font-size: 16px;
    font-weight: 600;
}

td {
    font-size: 14px;
    color: #495057;
}

td.status-approved {
    color: #28a72c;
    font-weight: 600;
}

td.status-rejected {
    color: #dc3545;
    font-weight: 600;
}

td.status-pending {
    color: #FFA500;
    font-weight: 600;
}

.no-orders-message {
    font-size: 16px;
    color: #dc3545;
}

.buttonRefresh {
    background-color: white;
    color: #4169E1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 2.5%;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: 0.5px solid #FFA500;
    transition: ease 0.5s background;
    width: 20%;
}
.buttonRefresh:hover {
    background-color: #FFA500;
    cursor: pointer;
}
</style>

<template>
    <div class="container">
      <h1>Şifremi Unuttum</h1>
      <div v-if="message" class="message">{{ message }}</div>
      <div v-if="error" class="error">{{ error }}</div>
      <div class="form-group">
        <label for="email">E-posta Adresi</label>
        <input type="email" v-model="email" placeholder="E-posta adresinizi girin" required />
      </div>
      <button @click="resetPassword" :disabled="isSubmitting">
        Şifre Sıfırlama Bağlantısı Gönder
      </button>
    </div>
  </template>
  
<script>
import { auth } from '../firebaseConfig';
import { sendPasswordResetEmail } from 'firebase/auth';

export default {
  name: 'ForgotPassword',
  data() {
    return {
      email: '',
      message: '',
      error: '',
      isSubmitting: false,
    };
  },
  methods: {
    async resetPassword() {
      this.isSubmitting = true;
      this.message = '';
      this.error = '';

      try {
        await sendPasswordResetEmail(auth, this.email);
        this.message = 'Şifre sıfırlama bağlantısı e-posta adresinize gönderildi.';
      } catch (error) {
        this.error = 'Şifre sıfırlama bağlantısı gönderilirken bir hata oluştu. Lütfen e-posta adresinizi kontrol edin ve tekrar deneyin.';
        console.error(error);
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
  </script>
  
<style scoped>
.container {
  max-width: 400px;
  margin: 0 auto;
  padding: 40px 20px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 10px 30px #4169e161;
  font-family: 'Arial', sans-serif;
  margin-top: 100px;
}

h1 {
  text-align: center;
  color: #333333;
  font-size: 24px;
  margin-bottom: 30px;
  font-weight: bold;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 10px;
  color: #333333;
  font-size: 14px;
  font-weight: bold;
}

input {
  width: 100%;
  padding: 12px;
  font-size: 14px;
  border: 0.2px solid #4169E1;
  border-radius: 6px;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
}

input:focus {
  border: 1px solid #4169E1;
  outline: none;
  box-shadow: 0 0 5px #4169e160;
}

button {
  width: 100%;
  padding: 12px;
  background-color: #4169E1;
  border: none;
  border-radius: 6px;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: bold;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

button:not(:disabled):hover {
  background-color: #FFA500;
}

.message, .error {
  text-align: center;
  padding: 10px;
  border-radius: 6px;
  font-size: 14px;
  margin-bottom: 20px;
}

.message {
  color: #155724;
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
}

.error {
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
}

</style>
<template>
  <div class="container">
      <header-page></header-page>
      <router-view />
      <bottom-header></bottom-header>
  </div>
</template>

<script>
  import HeaderPage from './components/HeaderPage.vue'
  import BottomHeader from './components/BottomHeader.vue'
  export default {
    name: 'App',
    components: {
      HeaderPage : HeaderPage,
      BottomHeader : BottomHeader
    },
    created(){
      this.$store.dispatch("initAuth")
    }
  }
</script>

<style>
  body {
    margin: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #f0f7ff;
  }
</style>

<template>
    <div class="how-container">
      <div class="slider">
        <div class="slide">
          <p>Öncelikle <strong>Kayıt</strong> olup ve <strong>Giriş</strong> yapmanız gerekiyor. Size ait herhangi bir e-posta adresi ile kayıt olabilirsiniz.</p>
          <img class="photosClass" src="../photos/image2.png" alt="deneme">
          <p>Sizin üzerinize kayıtlı olan <strong>(Ad Soyad ile)</strong> IBAN numaranızı girmelisiniz.</p>
          <p>Ardından hesap kodumuzu onaylatmamız gerekiyor...</p>
          <img class="photosClass" src="../photos/image3.png" alt="deneme">
          <p>Hesap kodu onaylaması için istenen hesap koduna 500 gold gönderiyoruz.</p>
          <img class="photosClass" src="../photos/image1.png" alt="deneme">
          <p>Ardından e-posta adresinizi doğrulamanız gerekiyor.</p>
          <img class="photosClass" src="../photos/image4.png" alt="deneme">
          <p>Yukarıdakine benzer bir e-posta doğrulama postası alacaksınız. Ardından açılan sayfada doğrulama geri bildirimi alacaksınız.</p>
          <img class="photosClass" src="../photos/image5.png" alt="deneme">
          <p>E-postanızın onaylandığına dair bir <strong>yeşil tik </strong> profil sayfanıza gelir.</p>
          <img class="photosClass" src="../photos/image6.png" alt="deneme">
          <p>Ve goldu attığınızı kontrol eden destek ekibimiz hesap kodunuza en kısa sürede onay verir. Aynı <strong>yeşil tik</strong> hesap kodunuz için de görünür hale gelir.</p>
          <img class="photosClass" src="../photos/image7.png" alt="deneme">
          <p>Ardından gold almak istiyorsanız Gold Satın Al kısmından belirlediğiniz gold miktarını seçip <strong>Sipariş Onayla</strong> butonuna tıklayabilirsiniz</p>
          <img class="photosClass" src="../photos/image9.png" alt="deneme">
          <p>Size verilen IBAN adresine hesap kodunuzu açıklamaya yazarak transfer yapmalısınız.</p>
          <p>Gold satmak istiyorsanız satmak istediğin gold miktarını seçip <strong>Satışı Onayla</strong> butonuna tıklamalısınız.</p>
          <img class="photosClass" src="../photos/image10.png" alt="deneme">
          <p>Verilen hesap kodu bilgisine goldunuzu göndermelisiniz.Her iki işlemde de ardından <strong>İşlem Geçmişim</strong> sayfasına yönlendirileceksiniz.</p>
          <img class="photosClass" src="../photos/image12.png" alt="deneme">
          <p>Sipariş durumunuzu buradan takip edebilirsiniz onaylandığı ya da reddedildiği durumlarda <strong>Sipariş Durumu</strong> güncellenecektir.</p>
          <img class="photosClass" src="../photos/image13.png" alt="deneme">
          <p>Herhangi bir sorun olması durumunda her saat yazabileceğiniz <strong>Destek</strong> sayfamızda sorunlarınızı bizle paylaşabilirsiniz.</p>
          <p><strong>Örneğin:</strong></p>
          <img class="photosClass" src="../photos/image14.png" alt="deneme">
          <img class="photosClass" src="../photos/image15.png" alt="deneme">
          <p><strong>Kimseyle uğraşmak zorunda kalmadan goldunuzu alıp satabilirsiniz.</strong></p>
          <p></p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'HowPage',
    props: {
      widthValue: {
        type: String,
        default: '30%'
      }
    },
    watch: {
      widthValue(newVal) {
        this.updateImageWidth(newVal);
      }
    },
    mounted() {
      this.updateImageWidth(this.widthValue);
    },
    methods: {
      updateImageWidth(value) {
        const images = document.querySelectorAll('.photosClass');
        images.forEach(img => {
          img.style.width = value;
        });
      }
    }
  };
  </script>
  
  <style scoped>
  .how-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
  
  .slider {
    display: flex;
    transition: transform 0.5s ease;
  }
  
  .slide {
    min-width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  
  .slide img {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
  
  .slide p {
    font-size: 16px;
    text-align: center;
  }
  
  .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 2em;
    cursor: pointer;
    padding: 10px;
  }
  
  .right-arrow {
    right: 10px;
  }
  
  .photosClass {
    width: 30%;
  }
  </style>
  
<template>
    <div class="container" v-if="isTrader">
        <div class="traderNick">
            <h3>Satıcı listesinde gözükmesini istediğiniz nick:</h3>
            <input class="traderNickInput" disabled v-model="traderInfo.traderNick">
            <div class="alert">Değiştirmek için destekle iletişime geçin</div>
        </div>
        <div class="changeCode">
            <h1>Kullanıcının ödeme için göreceği bilgiler</h1>
            <input placeholder="IBAN" class="payInfo" v-model="payInfo.iban" type="text">
            <input placeholder="Hesap Kodunuz" class="payInfo" v-model="payInfo.payCode" type="text">
            <button class="payButton" @click="updatePayInfo">Değiş</button>
        </div>

        <h1>Alış Siparişleri</h1>
        <div @click="fetchOrders" class="buttonRefresh">
            <i class="fas fa-sync-alt"></i>
        </div>
        <div class="scrollCode">
            <table class="scroll">
                <thead>
                    <tr>
                        <th> Tarih </th>
                        <th class="none">Ad Soyad</th>
                        <th class="none">Kullanıcı ID</th>
                        <th>Hesap Kodu</th>
                        <th>IBAN</th>
                        <th>Gold Miktarı</th>
                        <th>Fiyat</th>
                        <th>Durum</th>
                        <th>İşlemler</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(order, index) in sortedBuy" :key="order.orderId" :class="{'gray-background': index % 2 === 0, 'white-background': index % 2 !== 0}">
                        <td>{{ formatDate(order.date) }}</td>
                        <td class="none">{{ order.name }} {{ order.surname }}</td>
                        <td class="none">{{ order.userId }}</td>
                        <td>{{ order.code }}</td>
                        <td class="iban">{{ order.iban }}</td>
                        <td>{{ order.goldAmount }}M</td>
                        <td>{{ order.price }} TL</td>
                        <td :class="getStatusClass(order.status)">
                            {{ order.status === 'approved' ? 'Onaylandı' : order.status === 'rejected' ? 'Reddedildi' : 'Bekliyor' }}
                        </td>
                        <td>
                            <button v-if="order.status === 'pending'" @click="verifyOrder(order.orderId, order.userId, 'buy')">Onayla</button>
                            <button class="redClass" v-if="order.status === 'pending'" @click="rejectOrder(order.orderId, order.userId, 'buy')">Reddet</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        

        <h1>Satış Siparişleri</h1>
        <div @click="fetchOrders" class="buttonRefresh">
            <i class="fas fa-sync-alt"></i>
        </div>
        <div class="scrollCode">
            <table>
                <thead>
                    <tr>
                        <th> Tarih </th>
                        <th class="none">Ad Soyad</th>
                        <th class="none">Kullanıcı ID</th>
                        <th>Hesap Kodu</th>
                        <th>IBAN</th>
                        <th>Gold Miktarı</th>
                        <th>Fiyat</th>
                        <th>Durum</th>
                        <th>İşlemler</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(order, index) in sortedSell" :key="order.orderId" :class="{'gray-background': index % 2 === 0, 'white-background': index % 2 !== 0}">
                        <td>{{ formatDate(order.date) }}</td>
                        <td class="none">{{ order.name }} {{ order.surname }}</td>
                        <td>{{ order.userId }}</td>
                        <td>{{ order.code }}</td>
                        <td>{{ order.iban }}</td>
                        <td>{{ order.goldAmount }}M</td>
                        <td>{{ order.price }} TL</td>
                        <td :class="getStatusClass(order.status)">
                            {{ order.status === 'approved' ? 'Onaylandı' : order.status === 'rejected' ? 'Reddedildi' : 'Bekliyor' }}
                        </td>
                        <td>
                            <button v-if="order.status === 'pending'" @click="verifyOrder(order.orderId, order.userId, 'sell')">Onayla</button>
                            <button class="redClass" v-if="order.status === 'pending'" @click="rejectOrder(order.orderId, order.userId, 'sell')">Reddet</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div v-else>
        <p>Bu sayfaya erişim izniniz yok.</p>
    </div>
</template>

<script>
    import axios from 'axios';


export default {
    name: 'TraderPanel',
    data() {
        return {
            requests: [],
            buyOrders: [],
            sellOrders: [],
            complaints: [],
            searchUserId: '',
            userInfo: null,
            isAdmin: false,
            isTrader: false,
            payInfo : {},
            traderInfo : {},
            darkList : {},
            darkLists : {},
            darkListNew : {}
        };
    },
    computed: {
        sortedBuy() {
            return this.buyOrders.slice().sort((a, b) => new Date(b.date) - new Date(a.date));
        },
        sortedSell() {
            return this.sellOrders.slice().sort((a, b) => new Date(b.date) - new Date(a.date));
        },
    },
    methods: {
        async fetchOrders() {
            const idToken = await this.$store.dispatch('getToken');
            const localId = localStorage.getItem('localId');
            try {
                const buyResponse = await axios.get(`https://golder-31299-default-rtdb.firebaseio.com/trader-orders/${localId}.json?auth=` + idToken);
                const buyOrdersData = buyResponse.data;
                if (buyOrdersData) {
                    this.buyOrders = Object.keys(buyOrdersData).map(key => ({ orderId: key, ...buyOrdersData[key] }));
                } else {
                    this.buyOrders = [];
                }

                const sellResponse = await axios.get(`https://golder-31299-default-rtdb.firebaseio.com/trader-sales/${localId}.json?auth=` + idToken);
                const sellOrdersData = sellResponse.data;
                if (sellOrdersData) {
                    this.sellOrders = Object.keys(sellOrdersData).map(key => ({ orderId: key, ...sellOrdersData[key] }));
                } else {
                    this.sellOrders = [];
                }
            } catch (error) {
                console.error('Siparişler getirilirken hata oluştu:', error);
            }
        },
        async verifyOrder(orderId, userId, type) {
            const idToken = await this.$store.dispatch('getToken');
            const localId = localStorage.getItem('localId');
            try {
                const path = type === 'buy' ? 'orders' : 'sales';
                await axios.patch(`https://golder-31299-default-rtdb.firebaseio.com/${path}/${orderId}.json?auth=${idToken}`, { status: 'approved' });
                await axios.patch(`https://golder-31299-default-rtdb.firebaseio.com/trader-${path}/${localId}/${orderId}.json?auth=${idToken}`, { status: 'approved' });
                await axios.patch(`https://golder-31299-default-rtdb.firebaseio.com/user-${path}/${userId}/${orderId}.json?auth=${idToken}`, { status: 'approved' });
                this.fetchOrders();
            } catch (error) {
                console.error('Onaylama işlemi sırasında hata oluştu:', error);
            }
        },
        async rejectOrder(orderId, userId, type) {
            const localId = localStorage.getItem('localId');
            const idToken = await this.$store.dispatch('getToken');
            try {
                const path = type === 'buy' ? 'orders' : 'sales';
                await axios.patch(`https://golder-31299-default-rtdb.firebaseio.com/${path}/${orderId}.json?auth=${idToken}`, { status: 'rejected' });
                await axios.patch(`https://golder-31299-default-rtdb.firebaseio.com/trader-${path}/${localId}/${orderId}.json?auth=${idToken}`, { status: 'rejected' });
                await axios.patch(`https://golder-31299-default-rtdb.firebaseio.com/user-${path}/${userId}/${orderId}.json?auth=${idToken}`, { status: 'rejected' });
                this.fetchOrders();
            } catch (error) {
                console.error('Reddetme işlemi sırasında hata oluştu:', error);
            }
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            return date.toLocaleString();
        },
        async updatePayInfo(){
            const idToken = await this.$store.dispatch('getToken');
            const localId = localStorage.getItem('localId');
            axios.patch(`https://golder-31299-default-rtdb.firebaseio.com/traders/${localId}/payInfo.json?auth=${idToken}`, {
                iban : this.payInfo.iban,
                payCode : this.payInfo.payCode
            })
        },
        getStatusClass(status) {
            if (status === 'approved') return 'status-approved';
            if (status === 'rejected') return 'status-rejected';
            return 'status-pending';
        },
    },
    async created() {
        const idToken = await this.$store.dispatch('getToken');
        const localId = localStorage.getItem('localId');
        const response = await axios.get(`https://golder-31299-default-rtdb.firebaseio.com/users/${localId}.json?auth=${idToken}`);
        const user = response.data;
        const res = await axios.get(`https://golder-31299-default-rtdb.firebaseio.com/traders/${localId}.json?auth=${idToken}`)
        this.payInfo = res.data.payInfo
        this.traderInfo = res.data

        if (user.isTrader) {
            this.isTrader = true;
            this.fetchOrders();
            }
            },
            };
</script>

<style scoped>
.container {
    margin: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px #4169e15f;
}
h1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #343a40;
    border-bottom: 2px solid #4169E1;
    padding-bottom: 5px;
}
h2 {
    font-size: 20px;
    margin-top: 20px;
    color: #343a40;
    border-bottom: 2px solid #4169E1;
    padding-bottom: 5px;
}
table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px #4169e160;
}
thead {
    background-color: #4169E1;
    color: #fff;
}
th, td {
    padding: 10px;
    text-align: left;
    border-bottom: 0.2px solid #4169E1;
}
th {
    font-size: 14px;
    font-weight: 600;
}
td {
    font-size: 14px;
}
button {
    padding: 6px 12px;
    background-color: #4169E1;
    border: none;
    border-radius: 4px;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-right: 5px;
    margin: 5px
}
button:hover {
    background-color: #4169e160;
}
button:last-of-type {
    background-color: #dc3545;
}
button:last-of-type:hover {
    background-color: #dc354672;
}
p {
    font-size: 16px;
    color: #dc3545;
}
.user-info-section {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 50px;
}
.user-info-section input {
    padding: 10px 20px;
    font-size: 14px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    margin-right: 10px;
    flex: 1 1 200px;
    max-width: 100%;
}
.user-info-section button {
    padding: 10px 20px;
    background-color: #4169E1;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    flex: 1 1 100px;
}
.user-info-section button:hover {
    background-color: #FFA500;
}
.user-info-section input[type="text"] {
    width: auto;
    margin: 0 10px 10px 0;
}
.user-info-buttons {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}
.user-info-buttons button {
    flex: 1 1 100px;
    max-width: 100%;
}
.input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}
.input-group label {
    font-size: 14px;
    color: #495057;
    margin-bottom: 5px;
}
.input-group input {
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ced4da;
    border-radius: 4px;
}
.payInfo {
    padding: 5px;
    width: 35%;
    margin-left: 5px
}
.payButton {
    padding: 8px;
    margin-left: 5px;
}
.scrollCode {
    max-height: 400px;
    overflow: auto;
}
.gray-background {
    background-color: #f0f0f0;
}

.white-background {
    background-color: #ffffff;
}

td.status-approved {
    color: #28a72c;
    font-weight: 600;
}

td.status-rejected {
    color: #dc3545;
    font-weight: 600;
}

td.status-pending {
    color: #FFA500;
    font-weight: 600;
}

button.payButton {
    background-color: #4169E1;
}
button.redClass {
    background-color: #FFA500;
}
button.redClass:hover {
    background-color: #ffa60079;
}
.buttonRefresh {
    background-color: white;
    color: #4169E1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 85%;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: 0.5px solid #FFA500;
    transition: ease 0.5s background;
    width: 10%;
}
.buttonRefresh:hover {
    background-color: #FFA500;
    cursor: pointer;
}

.alert {
    color: #dc3545;
    font-size: 10px;
    margin-top: 3px;
    margin-left: 2px;
}
.darkListInput {
    width: 40%;
    padding: 7px;
}


@media (max-width: 1100px) {
    th, td {
        padding: 6px;
        font-size: 12px;
    }
    button {
        padding: 6px 12px;
        font-size: 12px;
    }
    h1 {
        font-size: 20px;
    }
    h2 {
        font-size: 18px;
    }
    .user-info-section {
        flex-direction: column;
    }
    button {
        width: 60px;
        margin-bottom: 10px;
        margin-top: 10px;
        padding: 10px;
    }
    .user-info-section {
        display: flex;
        flex-direction: row;
    }
    .userInfo {
        display: flex;
        flex-direction: column
    }
    label {
        padding: 5px;
    }
    .inputUser {
        padding: 5px;
    }
    .scrollCode {
    max-height: 400px;
    overflow: auto;
    }
}
</style>
<template>
    <div class="container">
        <div class="loginCard">
            <div v-if="isError" class="titles errorLogin">Kullanıcı hatalı!</div>
            <div class="sections">
                <div class="titles">E-mail</div>
                <input class="input" v-model="user.email" type="text">
            </div>
            <div class="sections">
                <div class="titles">Şifre</div>
                <input class="input" v-model="user.password" type="password">
            </div>
            <div class="sectionsHelp">
                <router-link class="sectionsHelp-x" to="/forgot-password">Şifrenizi mi unuttunuz?</router-link>
                <router-link class="sectionsHelp-x" to="/signup">Hesabınız yok mu?</router-link>
            </div>
            <div class="sectionsBtn">
                <button class="sectionsBtn-x" @click="signIn">Giriş Yap</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SignIn',
    data() {
        return {
            user: {
                email: '',
                password: '',
            },
        };
    },
    computed: {
        isError() {
            return this.$store.getters.isError;
        }
    },
    methods: {
        async signIn() {
            try {
                await this.$store.dispatch('signIn', { ...this.user });
                this.$router.push('/profil');
                
            } catch (error) {
                console.error(error);
                this.$store.commit('setError', 'Giriş hatalı!'); // Hata mesajını store'a kaydet
            }
        }
    },
    watch: {
        // Hata mesajını temizlemek için kullanıcı input değişikliği izlenebilir
        user: {
            handler() {
                if (this.isError) {
                    this.$store.commit('clearError');
                }
            },
            deep: true
        }
    }
};
</script>

<style scoped>
.container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f0f7ff;
    min-height: 100vh;
    padding: 20px;
}

.loginCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 400px;
    background-color: #ffffff;
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0 4px 20px #4169e15b;
    transition: transform 0.3s ease;
}

.loginCard:hover {
    transform: translateY(-5px);
}

.sections {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 15px 0;
}

.titles {
    font-size: 14px;
    font-weight: 700;
    color: #333;
    margin-bottom: 8px;
}

.input {
    padding: 12px;
    font-size: 16px;
    border: 0.4px solid #4169E1;
    border-radius: 6px;
    transition: border-color 0.3s, box-shadow 0.3s;
}

.input:focus {
    border-color: #FFA500;
    box-shadow: 0 0 0 3px #ffa6005f;
    outline: none;
}

.sectionsBtn {
    width: 100%;
    padding: 20px 0;
}

.sectionsBtn-x {
    width: 100%;
    padding: 15px;
    background-color: #4169E1;
    border-radius: 6px;
    border: none;
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
}

.sectionsBtn-x:hover {
    background-color: #FFA500;
    transform: translateY(-2px);
}

.sectionsHelp {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin: 10px 0;
}

.sectionsHelp-x {
    font-size: 14px;
    color: #4169E1;
    text-decoration: none;
    transition: color 0.3s;
}

.sectionsHelp-x:hover {
    color: #4169e1a6;
}

.errorLogin {
    font-size: 14px;
    color: #dc3545;
    margin-bottom: 15px;
    text-align: center;
    font-weight: 700;
}

.alerts {
    font-size: 12px;
    color: #dc3545;
    margin-top: 5px;
}

.none {
    display: none;
}

@media (max-width: 768px) {
    .loginCard {
        padding: 20px;
        max-width: 100%;
    }
    
    .titles {
        font-size: 12px;
    }
    
    .input {
        padding: 10px;
        font-size: 14px;
    }
    
    .sectionsBtn-x {
        padding: 12px;
        font-size: 14px;
    }
    
    .sectionsHelp-x {
        font-size: 12px;
    }
    
    .errorLogin {
        font-size: 12px;
    }
}
</style>
<template>
    <div class="container">
      <div v-if="userInfo.isBanned" class="ban-modal">
        <div class="ban-modal-content">
          <span class="close" @click="logout">&times;</span>
          <h2>Hesap Banlı</h2>
          <p>Hesabınız banlı durumda. Lütfen destek ile iletişime geçin.</p>
          <button @click="logout" class="ban-btn">Çıkış Yap</button>
        </div>
      </div>
      <div class="profilCard" v-else>
        <div>
            <div class="info none">Hesap bilgileriniz güncellendi!</div>
          <div class="infos">
            <label class="titles">Ad:</label>
            <input v-model="userInfo.name" disabled type="text" class="input-field">
          </div>
          <div class="infos">
            <label class="titles">Soyad:</label>
            <input v-model="userInfo.surname" disabled type="text" class="input-field">
          </div>
          <div class="infos">
            <label class="titles">Email:
              <i v-if="userInfo.emailVerified" class="checkmark">✔</i>
            </label>
            <input v-model="userInfo.email" disabled type="text" class="input-field">
          </div>
          <div class="infos">
            <label class="titles">Telefon:</label>
            <input v-model="userInfo.number" disabled type="tel" class="input-field">
          </div>
          <div class="infos">
            <label class="titles">Hesap Kodu:
              <i v-if="userInfo.isVerified" class="checkmark">✔</i>
            </label>
            <div class="input-group">
              <input v-model="userInfo.code" :disabled="!isCodeEditable" placeholder="Hesap Kodu" type="text" class="input-field">
            </div>
            <div class="warning forCode none">Girdiğiniz hesap kodu başkası tarafından kullanılıyor!</div>
            <div v-if="isRejected && !userInfo.code" class="warning">Hesap kodunuz reddedildi. Lütfen geçerli bir hesap kodu girin. Hesabınız silinebilir.</div>
          </div>
          <div class="infos">
            <label class="titles">IBAN:</label>
            <input v-model="userInfo.iban" :disabled="isIbanSet" placeholder="TRIBAN" type="text" class="input-field">
            <div class="warning forIban none">Geçerli bir IBAN adresi giriniz!</div>
          </div>
          <div class="infos">
            <div class="warning">Bir kez girdiğiniz IBAN adresini bir daha değiştiremezsiniz, lütfen dikkatle girin!</div>
          </div>
          <div class="infos infosBtn">
            <button @click="updateInfos" class="infoBtn">Güncelle</button>
          </div>
          <div v-if="(isRejected || userInfo.code) && !userInfo.isVerified && !reqState" class="infos infosBtn">
            <button @click="openVerificationModal" class="infoBtn">Hesap Kodunu Onaylat</button>
          </div>
          <div v-if="!userInfo.emailVerified" class="infos infosBtn">
            <button @click="sendEmailVerify" class="infoBtn">E-mail Doğrulama</button>
          </div>
        </div>
  
        <div v-if="showModal" class="modal">
          <div class="modal-content">
            <span class="close" @click="closeModal">&times;</span>
            <div v-if="!isVerified">
              <h2>Hesap Kodunu Onaylat</h2>
              <p>Onaylama isteği gönderildi. Hesap kodunu onaylamak için lütfen {{ payInfo.payCode }} kodlu hesaba 1 gold gönderin.</p>
              <button @click="requestVerification" class="modal-btn">Tamam</button>
            </div>
            <div v-else class="success-content">
              <i class="checkmark">✔</i>
              <p>Onaylama isteği gönderildi!</p>
            </div>
          </div>
        </div>
        <div v-if="userInfo.userNew" class="modal">
          <div class="modal-content-education">
            <h2>Yeni Kullanıcı Rehberi</h2>
            <how-page widthValue="80%"></how-page>
            <button @click="closeEducation" class="modal-btn">Kapat</button>
          </div>
        </div>
      </div>
    </div>
  </template>

<script>
import { getFunctions, httpsCallable } from 'firebase/functions';
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import HowPage from './HowPage.vue';

export default {
    name: 'ProfilPage',
    components : {
      HowPage : HowPage
    },
    data() {
        return {
            userInfo: {},
            isIbanSet: false,
            showModal: false,
            isVerified: false,
            isRejected: false,
            isCodeEditable: false,
            token: null,
            reqState : null,
            payInfo : {}
        };
    },
    methods: {
        ...mapActions(['getToken', 'checkEmailVerified', 'sendEmailVerify', 'logout']),
        async checkCodeEditable() {
            this.isCodeEditable = this.userInfo.code === "";
        },
        async updateInfos() {
            try {
                if (this.userInfo.iban && !(this.userInfo.iban.startsWith("TR") && this.userInfo.iban.length === 26)) {
                    document.querySelector('.forIban').classList.remove('none')
                    return;
                }

                await this.checkCodeEditable();

                if (this.isCodeEditable && this.userInfo.code) {
                    const functions = getFunctions();
                    const checkCodeExists = httpsCallable(functions, 'checkCodeExists');
                    const result = await checkCodeExists({ code: this.userInfo.code });

                    const { codeExists } = result.data;


                    if (codeExists) {
                        alert('Bu hesap kodu zaten mevcut. 2');
                        return;
                    }
                } else if (!this.isCodeEditable && this.userInfo.code && !this.userInfo.isVerified) {
                    // Tüm kullanıcıların kodlarını kontrol et
                    const allCodesResponse = await axios.get(`https://golder-31299-default-rtdb.firebaseio.com/codes.json`);
                    const allCodes = allCodesResponse.data;

                    const codeUsedByAnotherUser = Object.values(allCodes).some(entry => entry.code === this.userInfo.code && entry.localId !== this.userInfo.localId);

                    if (codeUsedByAnotherUser) {
                        document.querySelector('.forCode').classList.remove('none')
                        this.userInfo.code = ""
                        this.isCodeEditable = true
                        return;
                    }
                }

                await axios.patch(`https://golder-31299-default-rtdb.firebaseio.com/users/${this.userInfo.localId}.json?auth=${this.token}`, this.userInfo);
                await axios.put(`https://golder-31299-default-rtdb.firebaseio.com/codes/${this.userInfo.localId}.json`, {
                    code : this.userInfo.code,
                    verified : this.isVerified,
                    localId : this.userInfo.localId
                });
                this.isIbanSet = !!this.userInfo.iban;
                document.querySelector('.info').classList.remove('none')
                setTimeout(()=>{
                    document.querySelector('.info').classList.add('none')
                },1000)
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    alert("Kimlik doğrulama hatası, lütfen tekrar giriş yapın.");
                    this.logout();
                } else {
                    console.error(error);
                    alert("Bilgiler güncellenirken bir hata oluştu.");
                }
            }
        },
        async requestVerification() {
            try {
                const token = await this.getToken();
                await axios.post(`https://golder-31299-default-rtdb.firebaseio.com/verificationRequests.json?auth=${token}`, {
                    userId: this.userInfo.localId,
                    email: this.userInfo.email,
                    code: this.userInfo.code,
                    status: 'pending'
                });
                await axios.patch(`https://golder-31299-default-rtdb.firebaseio.com/users/${this.userInfo.localId}.json?auth=${this.token}`, {
                    reqState : true
                })
                this.isVerified = true;
                setTimeout(() => {
                    this.closeModal();
                    this.reqState = true
                }, 2000);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    alert("Kimlik doğrulama hatası, lütfen tekrar giriş yapın.");
                    this.logout();
                } else {
                    console.error(error);
                    alert("Onaylama isteği gönderilirken bir hata oluştu.");
                }
            }
        },
        async openVerificationModal() {
            const idToken = await this.$store.dispatch('getToken');
            const res = await axios.get(`https://golder-31299-default-rtdb.firebaseio.com/payInfo.json?auth=${idToken}`)
            this.payInfo = res.data
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
            this.isVerified = false;
        },
        async logout() {
            try {
                await this.$store.dispatch("logout");
                this.$router.push('/signin');
            } catch (error) {
                console.error('Çıkış yapma hatası:', error);
            }
        },
        async closeEducation(){
          this.userInfo.userNew = false
          await axios.patch(`https://golder-31299-default-rtdb.firebaseio.com/users/${this.userInfo.localId}.json?auth=${this.token}`, {
            userNew : false
          });
        }
    },
    async created() {
        try {
            const localId = localStorage.getItem('localId');
            const idToken = await this.$store.dispatch('getToken');
            if (!localId) {
                this.$router.push('/signin');
                return;
            }

            const res = await axios.get(`https://golder-31299-default-rtdb.firebaseio.com/payInfo.json?auth=${idToken}`)
            this.payInfo = res.data

            this.token = await this.getToken();
            const response = await axios.get(`https://golder-31299-default-rtdb.firebaseio.com/users/${localId}.json?auth=${this.token}`);
            this.userInfo = response.data || {};

            this.reqState = this.userInfo.reqState
            this.userInfo.localId = localId;
            this.isIbanSet = !!this.userInfo.iban;
            this.isRejected = response.data?.isRejected || false;
            this.isVerified = !!this.userInfo.isVerified;
            if (this.userInfo.isBanned) {
                this.$router.push('/support');
            }
            await this.checkCodeEditable();
            await this.checkEmailVerified();
        } catch (error) {
            console.error(error);
            this.$router.push('/support');
        }
    },
    computed: {
        ...mapGetters(['isAuthenticated', 'isBanned', 'isAdmin']),
    }
};
</script>

<style scoped>
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f7ff;
  min-height: 100vh;
}

.profilCard {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 6px #4169e161;
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 20px;
  margin-bottom: 70px;
}

.infos {
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

.titles {
  font-size: 14px;
  font-weight: 600;
  color: #495057;
  margin-bottom: 8px;
}

.input-field {
  padding: 12px;
  font-size: 14px;
  border: 0.4px solid #4169E1;
  border-radius: 6px;
  background-color: #f1f3f5;
  transition: border-color 0.3s, background-color 0.3s;
  width: 94%;
}

.input-field:focus {
  border: 1px solid #4169E1;
  outline: none;
  background-color: #ffffff;
}

.checkmark {
  margin-left: 5px;
  font-size: 15px;
  color: #28a72c;
}

.warning {
  font-size: 12px;
  color: #dc3545;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 5px;
}
.info {
  font-size: 16px;
  color: #28a72c;
  text-align: center;
  margin-top: 10px;
  font-weight: bold
}

.infosBtn {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
}

.infoBtn {
  width: 100%;
  padding: 12px;
  background-color: #4169E1;
  border-radius: 6px;
  border: none;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s;
}

.infoBtn:disabled {
  background-color: #adb5bd;
  cursor: not-allowed;
}

.infoBtn:not(:disabled):hover {
  background-color: #FFA500;
}

/* Modal styles */
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 6px #ffa6007c;
  width: 90%;
  max-width: 500px;
  text-align: center;
  position: relative;
}

.modal-content-education {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 6px #ffa6007c;
  width: 90%;
  max-width: 500px;
  max-height: 500px;
  overflow: auto;
  text-align: center;
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

.modal-btn {
  padding: 10px 20px;
  background-color: #4169E1;
  border: none;
  border-radius: 6px;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 20px;
}

.modal-btn:hover {
  background-color: #FFA500;
}

.success-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Ban Modal styles */
.ban-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.ban-modal-content {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 500px;
  text-align: center;
  position: relative;
}

.ban-btn {
  padding: 10px 20px;
  background-color: #dc3545;
  border: none;
  border-radius: 6px;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 20px;
}

.ban-btn:hover {
  background-color: #c82333;
}

.none {
    display: none;
}

@media (max-width: 768px) {
  .profilCard {
    width: 90%;
  }
}
</style>
<template>
  <div class="containerEx">
    <div class="selectTrader">
      <label class="alert">Sen de <strong>satıcı</strong> olabilirsin! Destekle iletişime geç ve <strong>satıcı ol!</strong></label>
      <label class="traderTitle">Aktif Satıcılar</label>
      <select v-model="selectedTrader" @change="updateTraderInfo" class="traderSelect" id="options" name="options">
        <option v-for="(trader, index) in traders" :key="index" :value="trader.userId">{{ trader.traderNick }}</option>
      </select>
    </div>
    <div class="container">
      <h1>Gold Alış ve Satış</h1>
      <div class="sections">
        <div class="section purchase-section">
          <h2>Gold Satın Al</h2>
          <label for="goldAmount">Almak istediğiniz gold miktarını seçin:</label>
          <select v-model="goldAmount" @change="updatePrice">
            <option v-for="amount in goldOptions" :key="amount" :value="amount">{{ amount }} milyon gold</option>
          </select>
          <p>Fiyat: {{ price }} TL</p>
          <button @click="confirmOrder">Siparişi Onayla</button>
        </div>

        <div class="section sale-section">
          <h2>Gold Sat</h2>
          <label for="goldAmountSale">Satmak istediğiniz gold miktarını seçin:</label>
          <select v-model="goldAmountSale" @change="updateSalePrice">
            <option v-for="amount in goldOptions" :key="amount" :value="amount">{{ amount }} milyon gold</option>
          </select>
          <p>Fiyat: {{ salePrice }} TL</p>
          <button @click="confirmSale">Satışı Onayla</button>
        </div>
      </div>

      <!-- Order Confirmation Modal -->
      <div v-if="orderConfirmed" class="modal">
        <div class="modal-content">
          <span class="close" @click="closeOrderConfirmation">&times;</span>
          <h2>Sipariş Onayı</h2>
          <div class="order-details">
            <div class="order-detail">
              <span class="detail-title">Gold Miktarı:</span>
              <span class="detail-value">{{ goldAmount }} milyon</span>
            </div>
            <div class="order-detail">
              <span class="detail-title">Birim Fiyat:</span>
              <span class="detail-value">115 TL</span>
            </div>
            <div class="order-detail">
              <span class="detail-title">Toplam Fiyat:</span>
              <span class="detail-value">{{ goldAmount }} x 115 = {{ price }} TL</span>
            </div>
          </div>
          <p>Lütfen aşağıdaki IBAN numarasına ödeme yapın:</p>
          <p><strong>{{ trader.payInfo.iban }}</strong></p>
          <p>Ödeme açıklamasına hesap kodunuzu yazmayı unutmayın.</p>
          <button @click="showCompleteOrderModal">Siparişi Tamamla</button>
        </div>
      </div>

      <!-- Sale Confirmation Modal -->
      <div v-if="saleConfirmed" class="modal">
        <div class="modal-content">
          <span class="close" @click="closeSaleConfirmation">&times;</span>
          <h2>Satış Onayı</h2>
          <div class="order-details">
            <div class="order-detail">
              <span class="detail-title">Gold Miktarı:</span>
              <span class="detail-value">{{ goldAmountSale }} milyon</span>
            </div>
            <div class="order-detail">
              <span class="detail-title">Birim Fiyat:</span>
              <span class="detail-value">100 TL</span>
            </div>
            <div class="order-detail">
              <span class="detail-title">Toplam Fiyat:</span>
              <span class="detail-value">{{ goldAmountSale }} x 100 = {{ salePrice }} TL</span>
            </div>
          </div>
          <p><strong>{{ trader.payInfo.payCode }}</strong> koduna goldu göndermelisiniz. Ödemeniz IBAN numaranıza gönderilecektir.</p>
          <button @click="showCompleteSaleModalFunc">Satışı Tamamla</button>
        </div>
      </div>

      <!-- Warning Modal -->
      <div v-if="showModal" class="modal">
        <div class="modal-content">
          <span class="close" @click="closeModal">&times;</span>
          <h2>Uyarı</h2>
          <p>IBAN adresinizi eklemeden, e-postanız ve hesap kodunuz onaylanmadan bu işlemi gerçekleştiremiyoruz.</p>
          <button @click="redirectToProfile">Profil Sayfasına Git</button>
        </div>
      </div>

      <!-- Complete Order Modal -->
      <div v-if="showCompleteModal" class="modal">
        <div class="modal-content">
          <span class="close" @click="closeCompleteModal">&times;</span>
          <h2>Onay</h2>
          <p>Siparişi tamamladığınızdan emin misiniz?</p>
          <p class="warning">Gereksiz işlemler hesabınızın kapanmasına neden olabilir.</p>
          <button @click="completeOrder">Evet, Siparişi Tamamla</button>
        </div>
      </div>

      <!-- Complete Sale Modal -->
      <div v-if="showCompleteSaleModal" class="modal">
        <div class="modal-content">
          <span class="close" @click="closeCompleteSaleModal">&times;</span>
          <h2>Onay</h2>
          <p>Satışı tamamladığınızdan emin misiniz?</p>
          <p class="warning">Gereksiz işlemler hesabınızın kapanmasına neden olabilir.</p>
          <button @click="completeSale">Evet, Satışı Tamamla</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

export default {
  data() {
    return {
      goldAmount: 5,
      goldAmountSale: 5,
      price: 575,
      salePrice: 500,
      goldOptions: [5, 6, 7, 8, 9, 10],
      orderConfirmed: false,
      saleConfirmed: false,
      showModal: false,
      showSaleModal: false,
      showCompleteModal: false,
      showCompleteSaleModal: false,
      orderId: '',
      saleId: '',
      userInfo: {
        email: '',
        code: '',
        iban: '',
        isVerified: false,
      },
      payInfo: {},
      traders: [],
      selectedTrader: '',
      trader: {
        payInfo: {
          iban: '',
          payCode: ''
        }
      },
    };
  },
  methods: {
    updatePrice() {
      this.price = this.goldAmount * 115;
    },
    updateSalePrice() {
      this.salePrice = this.goldAmountSale * 100;
    },
    async confirmOrder() {
      if (!this.userInfo.iban || !this.userInfo.isVerified || !this.userInfo.emailVerified) {
        this.showModal = true;
      } else {
        this.orderId = uuidv4();
        this.orderConfirmed = true;
      }
    },
    confirmSale() {
      if (!this.userInfo.iban || !this.userInfo.isVerified || !this.userInfo.emailVerified) {
        this.showModal = true;
      } else {
        this.saleId = uuidv4();
        this.saleConfirmed = true;
      }
    },
    showCompleteOrderModal() {
      this.showCompleteModal = true;
    },
    showCompleteSaleModalFunc() {
      this.showCompleteSaleModal = true;
    },
    async completeOrder() {
      const localId = localStorage.getItem('localId');
      const now = new Date();

      const orderData = {
        name : this.userInfo.name,
        surname : this.userInfo.surname,
        id: this.orderId,
        userId: localId,
        email: this.userInfo.email,
        code: this.userInfo.code,
        iban: this.userInfo.iban,
        goldAmount: this.goldAmount,
        payIban: this.trader.payInfo.iban,
        price: this.price,
        status: 'pending',
        type: 'buy',
        date: now.toISOString(),
        trader: this.trader
      };

      try {
        const idToken = localStorage.getItem("token");
        await axios.put(`https://golder-31299-default-rtdb.firebaseio.com/orders/${this.orderId}.json?auth=${idToken}`, orderData);
        await axios.put(`https://golder-31299-default-rtdb.firebaseio.com/trader-orders/${this.selectedTrader}/${this.orderId}.json?auth=${idToken}`, orderData);
        await axios.put(`https://golder-31299-default-rtdb.firebaseio.com/user-orders/${localId}/${this.orderId}.json?auth=${idToken}`, orderData);
        this.orderConfirmed = false;
        this.showCompleteModal = false;
        this.$router.push('/wallet');
      } catch (error) {
        console.error(error);
        alert('Siparişinizi tamamlarken bir hata oluştu.');
      }
    },
    async completeSale() {
      const localId = localStorage.getItem('localId');
      const now = new Date();

      const saleData = {
        name : this.userInfo.name,
        surname : this.userInfo.surname,
        id: this.saleId,
        userId: localId,
        email: this.userInfo.email,
        code: this.userInfo.code,
        iban: this.userInfo.iban,
        goldAmount: this.goldAmountSale,
        payCode: this.trader.payInfo.payCode,
        price: this.salePrice,
        status: 'pending',
        type: 'sell',
        date: now.toISOString(),
        trader: this.trader
      };

      try {
        const idToken = localStorage.getItem("token");
        await axios.put(`https://golder-31299-default-rtdb.firebaseio.com/sales/${this.saleId}.json?auth=${idToken}`, saleData);
        await axios.put(`https://golder-31299-default-rtdb.firebaseio.com/trader-sales/${this.selectedTrader}/${this.saleId}.json?auth=${idToken}`, saleData);
        await axios.put(`https://golder-31299-default-rtdb.firebaseio.com/user-sales/${localId}/${this.saleId}.json?auth=${idToken}`, saleData);
        this.saleConfirmed = false;
        this.showCompleteSaleModal = false;
        this.$router.push('/wallet');
        
      } catch (error) {
        console.error(error);
        alert('Satışınızı tamamlarken bir hata oluştu.');
      }
    },
    closeOrderConfirmation() {
      this.orderConfirmed = false;
    },
    closeSaleConfirmation() {
      this.saleConfirmed = false;
    },
    closeModal() {
      this.showModal = false;
    },
    closeCompleteModal() {
      this.showCompleteModal = false;
    },
    closeCompleteSaleModal() {
      this.showCompleteSaleModal = false;
    },
    redirectToProfile() {
      this.$router.push('/profil');
    },
    updateTraderInfo() {
      const selected = this.traders.find(trader => trader.userId === this.selectedTrader);
      if (selected) {
        this.trader = selected;
      }
    }
  },
  async mounted() {
    try {
      const idToken = localStorage.getItem("token");
      const userId = localStorage.getItem("localId");

      const userInfoResponse = await axios.get(`https://golder-31299-default-rtdb.firebaseio.com/users/${userId}.json?auth=${idToken}`);
      this.userInfo = userInfoResponse.data;

      const tradersResponse = await axios.get(`https://golder-31299-default-rtdb.firebaseio.com/traders.json?auth=${idToken}`);
      this.traders = Object.values(tradersResponse.data);

      this.selectedTrader = this.traders.length > 0 ? this.traders[0].userId : '';
      this.updateTraderInfo();
    } catch (error) {
      console.error(error);
    }
  }
};
</script>
               
<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 4px 8px #4169e15f;
  max-width: 900px;
  margin: 20px auto;
}

h1 {
  color: #343a40;
  margin-bottom: 30px;
  border-bottom: 2px solid #4169E1;
  padding-bottom: 10px;
  text-align: center;
  font-size: 28px;
}

.sections {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.section {
  width: 48%;
}

.purchase-section, .sale-section {
  background: #fff;
  padding: 50px;
  margin: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px #4169e160;
}

h2 {
  color: #343a40;
  margin-top: 20px;
  margin-bottom: 20px;
  border-bottom: 2px solid #4169E1;
  padding-bottom: 10px;
  font-size: 24px;
}

label {
  display: block;
  margin: 10px 0;
  font-size: 16px;
  color: #495057;
}

select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 20px;
  border: 1px solid #4169E1;
  border-radius: 4px;
}

p {
  font-size: 18px;
  margin: 10px 0;
}

button {
  padding: 12px 24px;
  background-color: #4169E1;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: rgba(0, 87, 179, 0.704);
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 6px #ffa60079;
  width: 90%;
  max-width: 500px;
  text-align: center;
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
}

.order-details {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.order-detail {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.detail-title {
  font-weight: bold;
  color: #343a40;
}

.detail-value {
  font-style: italic;
  color: #495057;
}

.warning {
  color: #dc3545;
  font-size: 14px;
  margin-top: 10px;
}

.selectTrader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
}

.traderTitle {
  font-size: 20px;
  font-weight: 700;
}

.traderSelect {
  width: 20%;
}

.alert {
  color : #4169E1
}

@media (max-width: 768px) {
  .purchase-section, .sale-section {
    padding: 15px;
  }

  .sections {
    flex-direction: column;
    align-items: center;
  }

  .section {
    width: 100%;
    margin-bottom: 20px;
  }

  .traderSelect {
      width: 40%;
    }
}
</style>
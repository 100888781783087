import { createStore } from 'vuex';
import axios from 'axios';
import router from "../router/index.js";

export default createStore({
  state: {
    token: "",
    apiKey: 'AIzaSyB5n1o5KycWbwsCHFeCMUf2NIE1PAtX7eU',
    error: '',
    userData: {
      email: '',
      localId: '',
      isBanned: false,
      isAdmin: false // Yeni ekleme
    }
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    clearToken(state) {
      state.token = "";
    },
    setError(state, error) {
      state.error = error;
    },
    clearError(state) {
      state.error = '';
    },
    setUser(state, user) {
      state.userData = {
        email: user.email,
        localId: user.localId,
        isBanned: user.isBanned || false,
        isAdmin: user.isAdmin || false, // Yeni ekleme
        isEmailVerified: user.emailVerified || false
      };
    },
    clearUser(state) {
      state.userData = {};
    },
    setBannedStatus(state, isBanned) {
      state.userData.isBanned = isBanned;
    }
  },
  actions: {
    async initAuth({ commit, dispatch }) {
      let token = localStorage.getItem("token");
      if (token) {
        let expirationDate = localStorage.getItem("expirationDate");
        let nowTime = parseInt(new Date().getTime());
        if (nowTime >= expirationDate) {
          console.log("Token süresi geçmiş");
          dispatch("logout");
        } else {
          commit('setToken', token);
          dispatch("setTimeoutTimer", expirationDate - nowTime);
          let localId = localStorage.getItem("localId");
          try {
            let response = await axios.get(`https://golder-31299-default-rtdb.firebaseio.com/users/${localId}.json?auth=${token}`);
            let userData = response.data;
            commit('setUser', userData);
            if (userData.isBanned) {
              commit('setBannedStatus', true);
              router.push('/support');
            } else {
              router.push("/");
            }
          } catch (error) {
            console.error(error);
            dispatch("logout");
          }
        }
      } else {
        router.push("/signin");
        return false;
      }
    },
    async signIn({ state, commit }, authData) {
      try {
        const response = await axios.post('https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=' + state.apiKey, {
          email: authData.email,
          password: authData.password,
          returnSecureToken: true
        });

        let localId = response.data.localId;
        let refreshToken = response.data.refreshToken;
        commit('setToken', response.data.idToken);
        localStorage.setItem('token', response.data.idToken);
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem("localId", localId);
        localStorage.setItem("expirationDate", parseInt(new Date().getTime() + ((response.data.expiresIn) * 1000)));

        const userResponse = await axios.post(
          'https://identitytoolkit.googleapis.com/v1/accounts:lookup?key=' + state.apiKey,
          { idToken: response.data.idToken }
        );
        const user = userResponse.data.users[0];
        commit('setUser', user);

        const userInfoResponse = await axios.get(`https://golder-31299-default-rtdb.firebaseio.com/users/${localId}.json?auth=${response.data.idToken}`);
        let userData = userInfoResponse.data;
        commit('setUser', userData);
        if (userData.isBanned) {
          commit('setBannedStatus', true);
          router.push('/support');
        } else {
          router.push("/");
        }
      } catch (error) {
        console.error(error);
        commit('setError', error); // Hata objesini sakla
      }
    },
    async signUp({ commit, state }, authData) {
      try {
        const response = await axios.post('https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=' + state.apiKey, {
          email: authData.email,
          password: authData.password,
          returnSecureToken: true
        });
        const userId = response.data.localId;
        const idToken = response.data.idToken;
        await axios.put(`https://golder-31299-default-rtdb.firebaseio.com/users/${userId}.json?auth=${idToken}`, {
          localId: userId,
          email: authData.email,
          name: authData.name,
          surname: authData.surname,
          code: authData.code,
          number: authData.number,
          isAdmin: false // Yeni kullanıcı admin değildir
        });
        return response;
      } catch (error) {
        commit('setError', error);
        throw error;
      }
    },
    logout({ commit }) {
      commit("clearToken");
      commit('clearUser');
      localStorage.removeItem("token");
      localStorage.removeItem("expirationDate");
      localStorage.removeItem('email');
      localStorage.removeItem('localId');
      router.replace('/signin');
    },
    setTimeoutTimer({ dispatch }, expiresIn) {
      setTimeout(() => {
        dispatch("logout");
      }, expiresIn);
    },
    async sendEmailVerify({ state }) {
      try {
        const response = await axios.post(`https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=${state.apiKey}`, {
          requestType: "VERIFY_EMAIL",
          idToken: state.token
        });
        console.log('E-posta doğrulama isteği gönderildi:', response.data);
      } catch (error) {
        console.error('E-posta doğrulama isteği gönderilirken hata oluştu:', error);
      }
    },
    async checkEmailVerified({ state, commit }) {
      try {
        const response = await axios.post(`https://identitytoolkit.googleapis.com/v1/accounts:lookup?key=${state.apiKey}`, {
          idToken: state.token
        });
        const user = response.data.users[0];
        const emailVerified = user.emailVerified;

        // Kullanıcı bilgilerini güncelle
        await axios.patch(`https://golder-31299-default-rtdb.firebaseio.com/users/${state.userData.localId}.json?auth=${state.token}`,{
          emailVerified : emailVerified
        })
        commit('setUser', user);

        return emailVerified;
      } catch (error) {
        console.error('E-posta doğrulama kontrolü hatası:', error);
        throw error;
      }
    },
    async getToken({ state, commit, dispatch }) {
      const token = state.token;
      if (token) {
        return token;
      }
      // Token yoksa, token'ın süresinin dolmuş olabileceğini düşünerek token yenileme işlemi yap
      const refreshToken = localStorage.getItem('refreshToken');
      if (refreshToken) {
        try {
          const response = await axios.post(`https://securetoken.googleapis.com/v1/token?key=${state.apiKey}`, {
            grant_type: 'refresh_token',
            refresh_token: refreshToken
          });
          const newToken = response.data.id_token;
          const newRefreshToken = response.data.refresh_token;
          const expiresIn = response.data.expires_in;

          // Yeni token ve refresh token'ı sakla
          commit('setToken', newToken);
          localStorage.setItem('token', newToken);
          localStorage.setItem('refreshToken', newRefreshToken);
          localStorage.setItem('expirationDate', parseInt(new Date().getTime() + (expiresIn * 1000)));

          return newToken;
        } catch (error) {
          console.error('Token yenileme hatası:', error);
          dispatch("logout");
          throw error;
        }
      } else {
        // Eğer refresh token yoksa, kullanıcıyı yeniden giriş yapmaya yönlendirin
        commit('clearToken');
        commit('clearUser');
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('expirationDate');
        localStorage.removeItem('email');
        localStorage.removeItem('localId');
        router.push('/signin');
        throw new Error('No token available, please sign in again');
      }
    }
  },
  getters: {
    isAuthenticated(state) {
      return state.token !== "";
    },
    isError(state) {
      return state.error !== '';
    },
    isBanned(state) {
      return state.userData.isBanned;
    },
    isAdmin(state) {
      return state.userData.isAdmin;
    }
  }
});
